import React, { createContext, useContext, useState } from 'react';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
const [messagecount, setMessagecount] = useState(0);

return (
<MessageContext.Provider value={{ messagecount, setMessagecount }}>
{children}
</MessageContext.Provider>
);
};

export const useMessageCount = () => useContext(MessageContext);