// ** React Imports
import React, { useState, useEffect } from 'react'; // Added 'React,'

// ** MUI Imports
import Box from '@mui/material/Box';
import {
    Container,
} from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import AlertTitle from '@mui/material/AlertTitle';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";

import useLogout from '../hooks/logouthook';
import { CardHeader } from '@mui/material';


// ** Icon Imports
import { FormHelperText, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';




const schema = yup.object().shape({
    password: yup.string().min(6).required('Password is required'),
    confirmNewPassword: yup
        .string()
        .required('Confirm New Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const SetPassword = ({checkPassowrd}) => {
    const [values, setValues] = useState({
        showNewPassword: false,
        showConfirmNewPassword: false,
    });

    const { handleLogout } = useLogout();

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
    };

    const handleClickShowConfirmNewPassword = () => {
        setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword });
    };

    const onSubmit = async (data) => {
        console.log(data) 
        Swal.fire({
            title: `Set Password`,
            text: `Are you sure you want to set the password?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00669e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm',
            reverseButtons: true,
            customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
            }
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/set-password`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            password: data.password,
                        })
                    })

                    if (response.status == 403 || response.status == 401) {

                        let refreshToken = ''
                        if (typeof localStorage !== 'undefined') {
                            refreshToken = window.localStorage.getItem('refreshToken')
                        }

                        try {
                            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    refreshToken: refreshToken
                                })
                            })
                            if (tokenResponse.status == 200) {
                                const tokendata = await tokenResponse.json()
                                window.localStorage.setItem('token', tokendata.accessToken)
                                token = tokendata.accessToken
                                onSubmit(data)
                            } else {
                                handleLogout();
                            }
                        } catch (error) {
                            console.error(error)
                        }
                    } else if (response.status == 200) {
                        const user = await response.json()
                        Swal.fire({
                            title: user.message, icon: 'success', confirmButtonColor: '#00669e', customClass: {
                                container: 'custom-swal-container', // Add a custom class to the container
                            },
                        });
                        checkPassowrd()
                        setValue('password', '')
                        setValue('confirmNewPassword', '')
                    } else {
                        const user = await response.json()
                        Swal.fire({
                            title: 'Cancelled',
                            text: user.message,
                            icon: 'error',
                            confirmButtonColor: '#00669e',
                            customClass: {
                                container: 'custom-swal-container', // Add a custom class to the container
                            },
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    icon: 'error',
                    confirmButtonColor: '#00669e',
                    customClass: {
                        container: 'custom-swal-container' // Add a custom class to the container
                    }
                })
            }
        })


    }

    return (
        <div>
            <Card sx={{ width: '100%', boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px' }}>
                <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Set Your Password</Typography>} sx={{ background: '#00669e', color: 'white', mb: 1 }} />
                <CardContent>
                    <Alert
                        icon={false}
                        sx={{
                            mb: 3,
                            backgroundColor: '#fff6e5', // Light yellow background
                            color: '#e4a52b', // Black text for contrast
                        }}
                    >
                        <AlertTitle
                            sx={{
                                fontWeight: 500,
                                mb: (theme) => `${theme.spacing(1)} !important`,
                                color: '#e4a52b' // Ensure the title text is also in black for visibility
                            }}
                        >
                            Ensure that these requirements are met
                        </AlertTitle>
                        Minimum 6 characters long, uppercase & symbol
                    </Alert>


                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel htmlFor='user-view-security-new-password'>New Password</InputLabel>
                                    <OutlinedInput
                                        label='New Password'
                                        id='user-view-security-new-password'
                                        {...register('password')}
                                        error={Boolean(errors.password)}

                                        type={values.showNewPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    edge='end'
                                                    onClick={handleClickShowNewPassword}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    aria-label='toggle password visibility'
                                                >
                                                    {values.showNewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText error id="user-view-security-current-password">
                                        {errors.password && errors.password.message}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel htmlFor='user-view-security-confirm-new-password'>Confirm New Password</InputLabel>
                                    <OutlinedInput
                                        label='Confirm New Password'
                                        id='user-view-security-confirm-new-password'
                                        {...register('confirmNewPassword')}
                                        error={Boolean(errors.confirmNewPassword)}
                                        type={values.showConfirmNewPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    edge='end'
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    aria-label='toggle password visibility'
                                                    onClick={handleClickShowConfirmNewPassword}
                                                >
                                                    {values.showConfirmNewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    <FormHelperText error id="user-view-security-current-password">
                                        {errors.confirmNewPassword && errors.confirmNewPassword.message}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{textAlign:'right'}}>
                                <Button type='submit' variant='contained' sx={{ backgroundColor: '#00669e', ":hover": { backgroundColor: '#00669e' } }}>
                                    Setup Password
                                </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default SetPassword
