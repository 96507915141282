import { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

// ** MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import MuiAvatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import {CircularProgress} from '@mui/material'

// ** Icon Imports
import { Icon } from '@iconify/react/dist/iconify.js'

// ** Configs
import themeConfig from '../config/themeConfig'




import { useMediaQuery } from '@mui/material'




// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: 450 }
}))

const MaskImg = styled('img')(({ theme }) => ({
  zIndex: -1,
  objectFit: 'cover',
  width: '100%',
  position: 'absolute',
  minHeight: '100vh'
}))

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate('');
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // ** Hook
  const theme = useTheme()
  const abovemd = useMediaQuery(theme.breakpoints.up('md'))

  const handleEnterForgotPassword = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    const parseemail = JSON.parse(searchParams.get('semail'))
    setEmail(parseemail);
  }, [searchParams]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email
          
        })
      })
      const data = await response.json();
      if (response.status == 200) {
        Swal.fire({
          text: data.message,
          icon: 'success',
          confirmButtonColor: '#00669e',
          customClass: {
            container: 'custom-swal-container' 
          }
        });
        setEmail('');
        navigate("/");
      }  else {
        Swal.fire({
          text: data.message,
          confirmButtonColor: '#00669e',
          customClass: {
            container: 'custom-swal-container' 
          }
        });
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error.message)
    }
  }

  return (
    <Box>
      <MaskImg src={`/images/forgotpasswordnew.png`} />

      <Box>
        {/* <MaskImg src={`/images/login/forgotpassword.png`} /> */}

        <Box
          sx={{
            display: 'flex',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5,

          }}
        >

          <Box sx={{ display: 'flex', boxShadow: '#0d204f 2px 3px 14px 2px;', backgroundColor: 'white', p: 4, borderRadius: 2, border: 6, borderColor: '#104a8b' }}>

            <Box sx={{ width: { xs: '75vw', lg: 430 }, height: { xs: '80vh', lg: 470 }, minHeight: 470, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              {/* <Box> */}
              <Box sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img
                  src='/images/et_logo.png'
                  alt='welcome'
                  style={{ width: '25%', height: 'auto', objectFit: 'contain', }} // Ensure image fills the container
                />
                <Typography variant='h5' sx={{ mb: 3, letterSpacing: '0.18px', fontWeight: 600, mt: 2 }}>
                  Forgot Password? 🔒
                </Typography>
                <Typography variant='body2' sx={{ textAlign: 'center' }}>
                  Enter your email and we&prime;ll send you instructions to reset your password
                </Typography>
              </Box>
              <form noValidate autoComplete='off' onSubmit={handleSubmit}>
                <TextField autoFocus type='email' label='Email' value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleEnterForgotPassword} size="small"  sx={{ display: 'flex', mb: 2 }} fullWidth />
                <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 2.6,"&.Mui-disabled": {
                                        backgroundColor: "#5BB4E4",
                                        color: "white",
                                    }, }} 
                // disabled={loading}  
                // startIcon={loading  ? (
                //   <CircularProgress size="30px"  />
                //   ) : null}
                // >
                //   Send reset link
                disabled={loading}
                  startIcon={loading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        color:'#fff'
                      }} />
                  ) : null}
                >
                  {loading ? "Please Wait" : "Send reset link"}
                </Button>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 3 }}>
                  <Link to="/" style={{ textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center' }}>
                    <Typography

                      sx={{
                        display: 'flex',
                        '& svg': { mr: 0.5 },
                        alignItems: 'center',
                        color: 'primary.main',
                        textDecoration: 'none',
                        justifyContent: 'center'
                      }}
                    >
                      <Icon icon='mdi:chevron-left' fontSize='1.5rem' />
                      <span>Back to login</span>
                    </Typography>
                  </Link>
                </Box>
              </form>
              {/* </Box> */}

            </Box>

          </Box>



        </Box>
      </Box >
    </Box >
  )
}


export default ForgotPassword
