import * as React from "react";
import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Checkbox, Grid } from "@mui/material";
import { TextField, Button, Typography, IconButton, InputAdornment, Box } from "@mui/material";
import Navbar from "../components/navbar";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import Swal from 'sweetalert2';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Helmet } from "react-helmet";
import themeConfig from '../config/themeConfig';
import { CircularProgress } from "@mui/material";
import UserDetails from "../components/loginPage/UserDetails";
import NavbarLog from "../components/navbarlogged";
import UserEmailDetails from "../components/loginPage/UserEmailDetails";



export default function SimpleContainer() {
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState({});
  const [errors1, setErrors1] = useState('');
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [nic, setnic] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [navbarPhonedetailsOpen, setNavbarPhonedetailsOpen] = useState(false)


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };


  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  };

  const validatePhoneNumber = (mobileNumber) => {
    return /^(0|\+94|94)?[0-9]{9}$/.test(mobileNumber);
  };



  // const validateEmail = (email) => {
  //   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  // };

  const validateEmail = (email) => {
    return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(email)
  };


  const validatePassword = (password) => {
    // return password.length > 5 && password.length < 8;
    return password.length > 7;
  };

  const validateNIC = (nic) => {

    const oldNICPattern = /^[0-9]{9}[vVxX]$/;

    const newNICPattern = /^[0-9]{12}$/;

    return (oldNICPattern.test(nic) || newNICPattern.test(nic))

  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());


    let newErrors = {};
    if (!validatePhoneNumber(data.mobile)) {
      newErrors.mobile = "Invalid phone number";
    }
    if (!validateEmail(data.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!validatePassword(data.password)) {
      newErrors.password = "Password must be with minimum 8 characters";
    }

    if (!validateNIC(data.nic)) {
      newErrors.nic = "Invalid NIC.Please enter the correct one.";
    }

    if (!firstname) {
      newErrors.firstname = "Please enter a First Name";
    }
    if (!lastname) {
      newErrors.lastname = "Please enter a Last Name";
    }
    if (!nic) {
      newErrors.nic = "Please enter a NIC";
    }
    if (!mobile) {
      newErrors.mobile = "Please enter a Mobile";
    }
    if (!email) {
      newErrors.email = "Please enter a Email";
    }
    if (!password) {
      newErrors.password = "Please enter a Password";
    }
    if (!agree) {
      newErrors.Checkbox = "Please agree our user agreements to proceed";
    }



    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      const date = new Date().toISOString().slice(0, 19).replace('T', ' ');

      const fetchData = async () => {

        // Capitalize names
        const capitalizedFirstName = capitalizeFirstLetter(firstname);
        const capitalizedLastName = capitalizeFirstLetter(lastname);
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/teacher_registration`, {
            method: 'POST',
            body: JSON.stringify({
              firstname: capitalizedFirstName,
              lastname: capitalizedLastName,
              nic,
              mobile,
              email,
              password,
              date

            }),
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error(`HTTP error: Status ${response.status}`);
          }


          const postsData = await response.json();

          if (postsData.message === 'Teacher registered successfully') {
            setfirstname("");
            setlastname("");
            setnic("");
            setmobile("");
            setemail("");
            setpassword("");
            setAgree(false);
            setErrors({});
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Form submitted successfully!',
              confirmButtonColor: '#00669e',
              confirmButtonText: 'OK'
            })
              .then((result) => {
                if (result.isConfirmed) {
                  window.location.href = `${process.env.REACT_APP_TEACHER_URL}/student_login`;
                }
              });
          } else if (postsData.message === 'You already have an account with this email') {
            setemail("");
            setAgree(false);
            setLoading(false);
            setErrors({});
            Swal.fire({
              icon: 'warning',
              title: 'Failed!',
              text: 'You already have an account with this email!',
              confirmButtonColor: '#00669e',
              confirmButtonText: 'OK'
            });
          } else {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Failed!',
              text: 'Sorry, there was an error!',
              confirmButtonColor: '#00669e',
              confirmButtonText: 'OK'
            });
          }



        } catch (err) {

        }
      };

      fetchData();
    } else {

      setErrors(newErrors);
    }

  };

  const handlefirstname = (event) => {
    const trimmedValue = event.target.value.trim(); // Remove leading and trailing spaces
    setfirstname(trimmedValue);
    setErrors("");
  };

  const handlelastname = (event) => {
    const trimmedValue = event.target.value.trim();
    setlastname(trimmedValue);
    setErrors("")
  };

  const handlenic = (event) => {
    setnic(event.target.value);
    setErrors("");
  };

  // const handlemobile=(event) => {
  //   setmobile(event.target.value);
  //   setErrors("")
  // };

  const handlemobile = (event) => {
    let valuemob = event.target.value;
    setErrors1("");

    if (!valuemob) {
      setErrors1("");
    }
    else if (!/^\+?[\d]+$/.test(valuemob)) {
      setErrors1("Invalid mobile number. Only digits and an optional '+' are allowed.");
    }
    else {
      if (valuemob.startsWith("7")) {
        if (valuemob.length !== 9) {
          setErrors1("Invalid mobile number. Must be 9 digits when starting with '7'.");
        }
      } else if (valuemob.startsWith("07")) {
        if (valuemob.length !== 10) {
          setErrors1("Invalid mobile number. Must be 10 digits when starting with '07'.");
        }
      } else if (valuemob.startsWith("947")) {
        if (valuemob.length !== 11) {
          setErrors1("Invalid mobile number. Must be 11 digits when starting with '947'.");
        }
      } else if (valuemob.startsWith("+947")) {
        if (valuemob.length !== 12) {
          setErrors1("Invalid mobile number. Must be 12 digits when starting with '+947'.");
        }
      } else {
        setErrors1("Invalid mobile number .");
      }
    }
    setmobile(valuemob);
  };

  const handleemail = (event) => {
    setemail(event.target.value);
    setErrors("")
  };

  const handlepassword = (event) => {
    setpassword(event.target.value);
    setErrors("")
  };


  return (
    <React.Fragment>
      <Helmet>
        <title>Teacher Signup - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
      {localStorage.getItem('studentID') ? <NavbarLog /> : <Navbar setOpenUserDetails={setOpenUserDetails} setNavbarPhonedetailsOpen={setNavbarPhonedetailsOpen} />}
      <Grid container xs={12} mt={6} sx={{ maxWidth: "lg" }}  >
        <img
          src="/images/teacher-banner.png"
          alt="Teacher-banner"
          style={{ width: "100%" }}
        />
      </Grid>


      <Grid container sx={{ display: "flex", justifyContent: "center", backgroundColor: '#fafafa' }}>
        <Container maxWidth="lg"  >
          <Typography
            variant="h4"
            sx={{ mt: 4, textAlign: "center" }}
          >
            Wanna Become An English Teacher?
          </Typography>

          <Grid container spacing={3} sx={{ mt: 3, mb: 5, display: "flex", justifyContent: "center" }} >

            <Grid item xs={12} sm={6} >
              <Card variant="outlined" sx={{ height: '100%' }} px="2" >
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={1.5} display="flex" flexDirection="row" alignItems="center" sx={{ mt: '4px' }}>
                      <img
                        src="/images/clock.png"
                        alt="clock"
                        style={{ width: 60 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={10.5} >
                      <Typography variant="h6" color="textPrimary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}  >
                        Choose time slots comfortable for you
                      </Typography>
                      <Typography variant="body2" color="textSecondary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}>
                        You can select time slots you wish to teach and deliver classes on those times.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>


            <Grid item xs={12} sm={6} >
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={1.5} display="flex" flexDirection="row" alignItems="center" sx={{ mt: '4px' }}>
                      <img
                        src="/images/salary.png"
                        alt="salary"
                        style={{ width: 60 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={10.5} >
                      <Typography variant="h6" color="textPrimary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}  >
                        Extra income for you
                      </Typography>
                      <Typography variant="body2" color="textSecondary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}>
                        Choose the time slots comfortable for you apart from your regular,busy office schedules. Deliver clasess and get paid.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>


            <Grid item xs={12} sm={6} >
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={1.5} display="flex" flexDirection="row" alignItems="center" sx={{ mt: '4px' }}>
                      <img
                        src="/images/student.png"
                        alt="student"
                        style={{ width: 60 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={10.5} >
                      <Typography variant="h6" color="textPrimary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}  >
                        Find students easily
                      </Typography>
                      <Typography variant="body2" color="textSecondary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}>
                        Students get teachers suggestions therefore more student interaction.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} >
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={1.5} display="flex" flexDirection="row" alignItems="center" sx={{ mt: '4px' }}>
                      <img
                        src="/images/stats.png"
                        alt="stats"
                        style={{ width: 60 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={10.5} >
                      <Typography variant="h6" color="textPrimary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}  >
                        Set your class rates
                      </Typography>
                      <Typography variant="body2" color="textSecondary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}>
                        You can decide and set the class fee for the classes. You can change the rate any time.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>


            <Grid item xs={12} sm={6} >
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={1.5} display="flex" flexDirection="row" alignItems="center" sx={{ mt: '4px' }}>
                      <img
                        src="/images/location.png"
                        alt="location"
                        style={{ width: 60 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={10.5} >
                      <Typography variant="h6" color="textPrimary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}  >
                        High accessibility with limited resources
                      </Typography>
                      <Typography variant="body2" color="textSecondary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}>
                        You can deliver classes from anywhere. You just need a PC and headphone to connect with our platform. You can conduct classes easily.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>


            <Grid item xs={12} sm={6} >
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={1.5} display="flex" flexDirection="row" alignItems="center" sx={{ mt: '4px' }}>
                      <img
                        src="/images/video-marketing.png"
                        alt="video-marketing"
                        style={{ width: 60 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={10.5} >
                      <Typography variant="h6" color="textPrimary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}  >
                        Upload your intro video
                      </Typography>
                      <Typography variant="body2" color="textSecondary" display="flex" flexDirection="row" alignItems="center" sx={{ mt: '2px' }}>
                        You can upload an intro video about how you teaches. It will create a positive image about you for student bookings.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

          </Grid>
        </Container>
      </Grid>

      <CssBaseline />

      <Container maxWidth="lg" sx={{ mb: 15 }}  >
        <Box sx={{
          mt: 6,
          mb: 6,
          display: "flex",
          justifyContent: "center",
          borderRadius: "12px",
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
          overflow: 'hidden',
        }}>
          <Grid container  >

            <Grid item
              md={5}
              display="flex "
              alignItems="right"
              sx={{
                display: {
                  xs: 'none',  // Display the image on extra-small screens and above
                  md: 'block',   // Hide the image on medium-sized screens and above
                }
              }}
            >
              <img
                src="/images/teacher.jpg"
                alt="Teacher"
                style={{ maxWidth: '100%', height: '100%' }}


              />
            </Grid>

            <Grid item
              md={7}
              sx={{
                paddingLeft: { xs: 2, md: 2 },
                paddingRight: { xs: 2, md: 2 },
                background:
                  " linear-gradient(90deg, rgba(0,98,204,1) 15%, rgba(16,24,71,1) 100%)",
                py: 4
              }}

            >

              <form

                style={{
                  width: "100%",
                  backgroundColor: "linear-gradient(to right , #101847, #00669e",
                  color: "white"
                }}
                onSubmit={handleSubmit}

              >
                <Typography
                  sx={{ textAlign: "center", fontSize: { xs: "25px", sm: "34px" } }}
                >
                  Teacher Registration
                </Typography>
                <Grid container spacing={3} sx={{ mt: 3, px: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      size="large"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",

                      }}
                      fullWidth
                      value={firstname}
                      onChange={handlefirstname}

                    />
                    {errors.firstname ?
                      <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                        {errors.firstname}
                      </Typography> : null
                    }
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      size="large"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",

                      }}
                      fullWidth
                      value={lastname}
                      onChange={handlelastname}

                    />
                    {errors.lastname ?
                      <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                        {errors.lastname}
                      </Typography> : null
                    }
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="nic"
                      name="nic"
                      placeholder="NIC"
                      size="large"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",
                      }}
                      fullWidth
                      value={nic}
                      onChange={handlenic}

                    />
                    {errors.nic ?
                      <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                        {errors.nic}
                      </Typography> : null
                    }
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      size="large"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",
                      }}
                      fullWidth
                      value={mobile}
                      onChange={handlemobile}
                    />

                    {errors.mobile ? (
                      <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                        {errors.mobile}
                      </Typography>
                    ) : null}

                    {errors1 ? (
                      <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                        {errors1}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="email"
                      name="email"
                      placeholder="Email"
                      size="large"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",
                      }}
                      fullWidth
                      value={email}
                      onChange={handleemail}
                    />
                    {errors.email ?
                      <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                        {errors.email}
                      </Typography> : null
                    }

                  </Grid >

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="password"
                      name="password"
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      size="large"
                      sx={{
                        backgroundColor: 'white',
                        borderRadius: '10px !important',
                      }}
                      fullWidth
                      value={password}
                      onChange={handlepassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.password && (
                      <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                        {errors.password}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Checkbox
                        checked={agree}
                        onChange={handleAgreeChange}
                        inputProps={{ "aria-label": "agree to terms" }}
                        sx={{
                          color: '#ffffff',
                          '&.Mui-checked': {
                            color: '#ffffff',
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 24
                          }
                        }}

                      />
                      <Typography variant="body2">
                        I agree to EnglishTeacher.lk
                        <a href="/teacher_agreement" target="_blank" style={{ color: 'yellow', marginLeft: 5, marginRight: 5, textDecoration: 'none', fontWeight: 800 }}>teacher agreement</a>
                        and acknowledge that.
                      </Typography>
                    </Box>
                  </Grid>
                  {errors.Checkbox ?
                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 0, marginLeft: 20 }}>
                      {errors.Checkbox}
                    </Typography>
                    : null
                  }

                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}
                  >

                    <Button
                      type="submit"
                      variant="contained"

                      size="md"
                      sx={{
                        color: "white",
                        backgroundColor: "primary",
                        "&:hover": {
                          backgroundColor: "#0d65c8",
                        },
                        "&.Mui-disabled": {
                          backgroundColor: "#5BB4E4",
                          color: "white",
                        },
                      }}
                      disabled={loading}
                      startIcon={loading ? (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            color:'#fff'
                          }} />
                      ) : null}
                    >
                      {loading ? "Please Wait" : "Sign Up As A Teacher"}
                    </Button>

                  </Grid>

                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Box sx={{fontWeight: 80, color:'#ffffff',fontSize:14}}>
                      Already registered?
                      <a
                        href={`${process.env.REACT_APP_TEACHER_URL}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          marginLeft: 5,
                          color: "#5BB4E4",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        Sign in
                      </a>
                    </Box>
                  </Grid>

                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>

      </Container>

      <Footer />


      {/* navbar email dialog */}
      <UserDetails setOpenUserDetails={setOpenUserDetails} openUserDetails={openUserDetails} />

      {/* navbar phone dialog */}
      <UserEmailDetails navbarPhonedetailsOpen={navbarPhonedetailsOpen} setNavbarPhonedetailsOpen={setNavbarPhonedetailsOpen} />
    </React.Fragment>
  );
}
