import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import CircularIndeterminate from '../components/loading';
import { Box } from '@mui/material';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import useLogout from '../hooks/logouthook';
import {Typography} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const columns = [
    { id: 'date_time', label: 'Date-Time', minWidth: 80,align: 'left', },
    { id: 'class', label: 'Log', minWidth: 80, align: 'left'},
    { id: 'previous_balance', label: 'Previous Balance', minWidth: 80, align: 'right',format: (value) => ` ${value.toFixed(2)}` },
    { id: 'amount', label: 'Payment', minWidth: 80, align: 'right',format: (value) => ` ${value.toFixed(2)}`},
    { id: 'new_balance', label: 'New Balance', minWidth: 80, align: 'right',format: (value) => ` ${value.toFixed(2)}`},
  ];

  // function formatDate(dateString) {
  //   const date = new Date(dateString);
  //   return date.toISOString().split('T')[0];
  // }
  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function BillingHistoryTable() {

    const [billingHistoryData, setBillingHistoryData] = useState([]);
    const studentID = localStorage.getItem('studentID');
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 25;
    const [loading, setLoading] = React.useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const navigate = useNavigate();
    const {handleLogout} = useLogout()

    let token = ''
    if (typeof localStorage !== 'undefined') {
      token = window.localStorage.getItem('token')
    }
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    useEffect(() => {
          fetchBillingHistoryData();
      }, [studentID, page, rowsPerPage]);
      
      const fetchBillingHistoryData = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/billing_history`, {
            method: 'POST',
            body: JSON.stringify({ 
              studentID, 
              page: page + 1, 
              limit: rowsPerPage 
             }),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });

          if (response.status === 403 || response.status === 401) {
            let refreshToken = '';
            if (typeof localStorage !== 'undefined') {
              refreshToken = window.localStorage.getItem('refreshToken');
            }
        
            try {
              const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  refreshToken: refreshToken,
                }),
              });
        
              if (tokenResponse.status === 200) {
                const tokendata = await tokenResponse.json();
                window.localStorage.setItem('token', tokendata.accessToken); 
                token = tokendata.accessToken;
        
        
                return fetchBillingHistoryData();
              } else {
                handleLogout();
              }
            } catch (error) {
              console.error('Error refreshing token:', error);
              handleLogout(); 
            }
        
            return; 
          }else{
            const getData = await response.json();
            setBillingHistoryData(getData.data); 
            setTotalItems(getData.totalItems); 
            setLoading(false);
            console.log(getData.data);
      
          }
      
          if (!response.ok) {
            throw new Error(`HTTP error: Status ${response.status}`);
          }
          } catch (err) {
            console.error('Failed to fetch data', err);
            setBillingHistoryData([]);
            setLoading(false);
          }
        };
      
  
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 700 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularIndeterminate />
          </Box>
        ) : (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {billingHistoryData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                  <Typography
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#c0c0c0' }}>
                      <ErrorOutlineIcon sx={{ mr: 1 }} />
                      No records available
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
              billingHistoryData.map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    const isClass = row.class === 1; // Assuming `row.class` holds the class/top-up status
                
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === 'class' ? (
                          isClass ? (
                           
                            // <Chip label={`Class-(${row.ssch_id})`} color="success" sx={{ width: 120, height: 18 }} />
                            row.previous_balance < row.new_balance ? (
                              row.comment === "Cancel Class By admin" ? (
                                <Typography sx={{   color: "black", fontSize: 14 }}>
                                  {`Cancelled Class-(${row.ssch_id})`}
                                </Typography>
                              ) : row.comment === "Disapproved by teacher" ? (
                                <Typography sx={{   color: "black", fontSize: 14 }}>
                                {`Disapproved Class-(${row.ssch_id})`}
                              </Typography>
                              ) : (
                                <Typography sx={{  color: "black", fontSize: 14 }}>
                                  {`Reschedule Class-(${row.ssch_id})`}
                                </Typography>
                              )
                            ) : (
                              <Typography sx={{  color: "black", fontSize: 14 }}>
                                {`Class-(${row.ssch_id})`} 
                              </Typography>
                            )
                          ) : (
                            <Typography sx={{ color: "black", fontSize: 14 }}>
                              {`Top-Up-(${row.pay_method})(${row.pay_id})`}
                            </Typography>
                          )
                        ) : column.id === 'date_time' ? (
                          formatDate(value)
                        ) : column.id === 'amount' ? (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: column.align }}>
                            {row.previous_balance < row.new_balance ? (
                              <Icon icon="gg:arrow-up-r" width="1rem" height="1rem" style={{color: "#08af1c", marginRight: '0.5rem'}} />
                            ) : (
                              <Icon icon="gg:arrow-down-r" width="1rem" height="1rem" style={{color: "#da0707", marginRight: '0.5rem'}} />
                            )}
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </div>
                        ) : (
                          column.format && typeof value === 'number' ? column.format(value) : value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
               ))
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {totalItems > 25 && (
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        )}
    </Paper>
  );
}
  

    
export default BillingHistoryTable;