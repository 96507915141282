import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Avatar,
  Typography,
  Box,
  Stack,
  Paper,
  Chip,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {useNavigate } from 'react-router-dom';
// import TablePagination from '@mui/material/TablePagination';
import CircularIndeterminate from '../components/loading';
import TablePagination from '@mui/material/TablePagination';
import TodayClassCard from './todayclassjoin/TodayClassCard';
import useLogout from '../hooks/logouthook';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function TodayCard1() {
  const [todayClassData, setTodayClassData] = useState([]);
  const studentID = localStorage.getItem('studentID');
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const itemsPerPage = 6;
  const [loading, setLoading] = useState(true);
  const [serverTime, setServerTime] = useState(new Date());
  const {handleLogout} = useLogout();
  const navigate = useNavigate('');

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchTodayClassData(page);
  }, [studentID, page]);

  const fetchTodayClassData = async (page) => {
    setLoading(true);
    try {
    
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/today_class`, {
        method: 'POST',
        body: JSON.stringify({
          studentID,
          page,
          itemsPerPage,
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, 
        },
      });
    
      // Check if response is unauthorized or forbidden
      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }
    
        // Try to refresh the token
        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });
    
          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken); // Store new token
            token = tokendata.accessToken;
    
            // Retry fetching today's class data with the new token
            return fetchTodayClassData(page);
          } else {
            handleLogout(); // Logout if token refresh fails
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout(); // Logout on token refresh failure
        }
    
        return; // Exit the function after handling token refresh
      }
    
      // If the response is okay, proceed with the rest of the logic
      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }
    
      const getData = await response.json();
      const serverTime = new Date(getData.serverTime);
    
      setTotalItems(getData.totalItems);
    
      const updatedData = getData.data.map((item) => {
        const timeLeft = calculateTimeLeft(serverTime, item.schedule_date, item.start_time);
        return {
          ...item,
          timeLeft,
        };
      });
    
      setTodayClassData(updatedData);
      setServerTime(serverTime);
      setLoading(false);
    
    } catch (err) {
      setTodayClassData([]);
      setLoading(false);
      console.error('Error fetching class data:', err);
    }
    };

  const calculateTimeLeft = (serverTime, scheduleDate, startTime) => {
    try {
      const now = new Date(serverTime);
      const nowHours = now.getHours();
      const nowMinutes = now.getMinutes();
      const nowSeconds = now.getSeconds();

      const [startHours, startMinutes, startSeconds] = startTime.split(':').map(Number);

      // Calculate the difference in seconds
      const nowInSeconds = nowHours * 3600 + nowMinutes * 60 + nowSeconds;
      const startInSeconds = startHours * 3600 + startMinutes * 60 + (startSeconds || 0);

      let differenceInSeconds = startInSeconds - nowInSeconds;

     // If the difference is negative, it means the class has already ended
    if (differenceInSeconds < 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

      const hours = Math.floor(differenceInSeconds / 3600);
      const minutes = Math.floor((differenceInSeconds % 3600) / 60);
      const seconds = differenceInSeconds % 60;

      return {
        hours,
        minutes,
        seconds,
      };
    } catch (error) {
      console.error('Error calculating time left:', error);
      return { hours: 0, minutes: 0, seconds: 0 };
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setServerTime(prevServerTime => {
        const newServerTime = new Date(prevServerTime.getTime() + 1000);
        setTodayClassData(prevData =>
          prevData.map(item => {
            const timeLeft = calculateTimeLeft(newServerTime, item.schedule_date, item.start_time);
            return { ...item, timeLeft };
          })
        );
        const allTimersEnded = todayClassData.every(item => item.timeLeft === null);
        if (allTimersEnded) {
          clearInterval(interval);
        }

        return newServerTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [todayClassData]);

  const handlehome = () => {
    navigate('/')
  }

  return (
    <div>
      <Card sx={{ mb: 1 }}>
        <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Today Classes</Typography>} sx={{ background: '#00669e', color: 'white', mb:1 }} />
        <CardContent>
        {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
             <CircularIndeterminate />
           </Box>
        ) : (
          <>
          {todayClassData.length === 0 ? (
            <>
             <Typography 
               sx={{ mx: 'auto', display: 'flex', alignItems: 'center', justifyContent:'center', gap:1, color: '#c0c0c0', mb:2 }}>
               <ErrorOutlineIcon /> There are no today classes
             </Typography>
            
            <Grid container direction="column" justifyContent="center" alignItems="center" sx={{backgroundColor:"#dbe9f2b8", borderRadius:2, py:2,  boxShadow: "inset 0px 0px 2px #10184724;", cursor: "pointer" }}>
            <ControlPointIcon sx={{ fontSize: 50, mb: 1, color:"#00669eab"  }} onClick={handlehome} />
            <Typography onClick={handlehome} sx={{ justifyContent: "center", textAlign: 'center', color:"#00669eab" }}>Book A Class</Typography>
          </Grid>
          </>
          ) : (
            <Grid container spacing={2}>
            {todayClassData.map((item) => (
              <Grid item key={item.ssch_id} xs={12} sm={6} lg={4}>
               <TodayClassCard item={item}/>
              </Grid>
           ))}
            </Grid>
          )}
          </>
        )}
         {totalItems > 3 && (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={itemsPerPage}
              rowsPerPageOptions={[]}
            />
             {/* <Button variant="contained" size='small' onClick={handleViewAll} sx={{mr:2, backgroundColor: "#0d2353"}}>
              View All
            </Button> */}
          </Box>
        )}
        </CardContent>
      </Card>
    </div>
  );
}

export default TodayCard1;