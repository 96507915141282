import { CircularProgress, Typography, Chip } from '@mui/material'
import React, { useState, forwardRef, useEffect } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react/dist/iconify.js'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert2'

const TicketCard = ({ item, fetchAllTicket }) => {
    const navigate = useNavigate();

    let adminId = ''
    if (typeof localStorage !== 'undefined') {
        adminId = window.localStorage.getItem('studentID')
    }

    const getStatusColor = priority => {
        return priority === 'immediate'
            ? '#D52D00'
            : priority === 'high'
                ? '#DA5301'
                : priority === 'medium'
                    ? '#FFA300'
                    : priority === 'low'
                        ? '#FFBE64'
                        : null
    }

    const getStatusName = priority => {
        return priority === 'immediate'
            ? 'Immediate'
            : priority === 'high'
                ? 'High'
                : priority === 'medium'
                    ? 'Medium'
                    : priority === 'low'
                        ? 'Low'
                        : null
    }

    const formatedate = allDate => {
        const newDates = new Date(allDate)
        const formattedDate = newDates.toISOString().split('T')[0]

        return formattedDate
    }

    const handleClick = () => {
        

        if (item.stu_status == 'unread') {
            console.log(item)
            unreadTicket(item.id)
        }
        navigate(`/ticket/view/${item.id}`);

    }

    const handleClose = (id) => {
        Swal.fire({
            title: `Close ticket ${id}`,
            text: 'Are you sure you want to close this ticket?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00669e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm',
            reverseButtons: true,
            customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
            }
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/ticket-close`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            id: id,
                            adminId: adminId
                        })
                    })

                    const data = await response.json()
                    if (response.status === 200) {
                        Swal.fire({
                            title: 'Confirmed!',
                            icon: 'success',
                            confirmButtonColor: '#00669e',
                            customClass: {
                                container: 'custom-swal-container' // Add a custom class to the container
                            }
                        })
                        fetchAllTicket()
                    }
                } catch (error) {
                    console.error(error)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    icon: 'error',
                    confirmButtonColor: '#00669e',
                    customClass: {
                        container: 'custom-swal-container' // Add a custom class to the container
                    }
                })
            }
        })
    }

    const timedifference = (item) => {
        const date1 = new Date()
        const date2 = new Date(item.admin_date?.send_date ? item.admin_date?.send_date : item.date)
        const diffTime = (date1 - date2)

        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
        const remainingHour = diffTime - (diffDays * 1000 * 60 * 60 * 24);
        const diffHours = Math.floor(remainingHour / (1000 * 60 * 60));
        const remainingminute = remainingHour - (diffHours * 1000 * 60 * 60);
        const diffMinutes = Math.floor(remainingminute / (1000 * 60));

        return `${diffDays}d ${diffHours}h ${diffMinutes}m`
    }

    const unreadTicket = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tickets/unread-ticket`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,

                })
            })

        } catch (error) {
            console.log(error)
        }
    }

    const getCurrentStatus = priority => {
        return priority === 'pending'
            ? '#FFA500'
            : priority === 'closed'
                ? '#DC3545'
                : priority === 'hold'
                    ? '#007BFF'
                    : priority === 'ongoing'
                        ? '#28A745'
                    : null
    }

    return (
        <div>
            <Box sx={{ mt: 1 }}>
                <Card sx={{ backgroundColor: item.stu_status == 'unread' ? '#B0BEC5' : null }}>
                    <CardContent>
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1, justifyContent:'center'}}>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: { sm: 'center' }, justifyContent: 'space-between', flexDirection: { xs: 'column', sm: 'row' }, flexGrow: 1, cursor: 'pointer' }} onClick={handleClick}>

                                <Box sx={{ display: 'flex', gap: 1, alignItems: {xs:'flex-start', md:'center'}, flexDirection:{xs:'column', md:'row'} }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center'}}>

                                    <Box sx={{ display: 'flex', mr: 2, gap: 1, }}>
                                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                            {item.rep_status == 'admin' ? <Icon icon='noto-v1:man-technologist' /> : <Icon icon='noto:man-raising-hand' />}
                                        </Box>
                                        <div style={{ fontWeight: '300' }}>
                                            #{item.id}
                                        </div>
                                    </Box>


                                    <div style={{ fontWeight: '600' }}>{item.subject}  </div>
                                </Box>

                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center'}}>

                                    <Chip
                                        label={getStatusName(item.priority)}
                                        size='small'
                                        sx={{
                                            backgroundColor: getStatusColor(item.priority),
                                            color: '#ffffff',
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            '& .MuiChip-label': {
                                                textOverflow: 'clip'
                                            },
                                            cursor: 'pointer'
                                        }}
                                    />

                                    <Chip
                                        label={item?.status}
                                        size='small'
                                        sx={{
                                            backgroundColor: getCurrentStatus(item?.status),
                                            color: '#ffffff',
                                            fontSize: '12px',
                                            fontWeight: 'bold',
                                            '& .MuiChip-label': {
                                                textOverflow: 'clip'
                                            },
                                            cursor: 'pointer',
                                            textTransform: 'capitalize'
                                        }}
                                    />

                                    {(item?.close_comment == 'reopened ticket by admin' || item?.close_comment == 'reopened ticket by student') && (
                                        <Chip
                                            label={'Reopened'}
                                            size='small'
                                            sx={{
                                                backgroundColor: '#7F889B',
                                                color: '#ffffff',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                '& .MuiChip-label': {
                                                    textOverflow: 'clip'
                                                },
                                                cursor: 'pointer'
                                            }}
                                        />
                                    )}
                                    </Box>

                                </Box>

                                <Box onClick={handleClick} sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, justifyContent: 'space-between', minWidth:100 }}>
                                    <div>{formatedate(item.date)}</div>
                                    {item.status !== 'closed' && (
                                        <div>{timedifference(item)}</div>
                                    )}
                                </Box>
                            </Box>

                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>

                                <div style={{ backgroundColor: '' }}>
                                    {item.status !== 'closed' && (
                                        <Tooltip title="Close" placement="top">
                                            <IconButton onClick={() => handleClose(item.id)}>
                                                <Icon icon="carbon:close-filled" style={{ color: 'red' }} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>

                            </Box>
                        </Box>

                        <Typography variant="caption" gutterBottom sx={{ display: 'block', textDecoration: 'underline', cursor: 'pointer' }} color="primary" onClick={handleClick}>View ticket</Typography>
                    </CardContent>
                </Card>
            </Box>
        </div>
    )
}

export default TicketCard
