import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Box } from '@mui/material'
import Swal from 'sweetalert2'
// import Icon from 'src/@core/components/icon'
import TodayIcon from '@mui/icons-material/Today';
import TextField from '@mui/material/TextField'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Grid from '@mui/material/Grid';
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { EditorState, ContentState, convertFromHTML, Modifier } from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { CircularProgress } from "@mui/material";

const EditPost = ({ postOpen, setPostOpen, postObj, setTableData, fetchReset }) => {
    const [post, setPost] = useState('')
    const [postImage, setPostImage] = useState([])
    const [imagList, setImagList] = useState([])
    const [imageFiles, setImageFiles] = useState([])
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false);

    let adminId = ''
    if (typeof localStorage !== 'undefined') {
        adminId = window.localStorage.getItem('studentID')
    }

    useEffect(() => {
        if (postObj?.text) {
            const blocksFromHTML = convertFromHTML(postObj?.text);

            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        }

        setPost(postObj ? postObj.text : '')
        setPostImage(postObj ? postObj.img : [])
        setImagList(postObj ? postObj.img : [])

    }, [postObj, postOpen])

    const handleClose = () => {
        setPostOpen(false)

    }

    const handleSubmit = () => {
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const contentHTML = draftToHtml(rawContent);

        setPost(contentHTML)

        const formData = new FormData()
        imageFiles.forEach((file, index) => {
            formData.append('files', file); // 'files' is the field name expected by Multer
        });
        formData.append('post', contentHTML)
        formData.append('id', postObj.fro_id)
        formData.append('image', JSON.stringify(imagList))

        Swal.fire({
            text: 'Are you sure you want to Edit this post?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00669e',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
            }
        }).then(async result => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/edit-post`, {
                        method: 'POST',
                        body: formData
                    })
                    if (response.status == 200) {
                        Swal.fire({
                            title: 'Post Submitted!',
                            text: 'Your post will be visible once approved by the admin.',
                            icon: 'success',
                            confirmButtonColor: '#00669e',
                            customClass: {
                                container: 'custom-swal-container' // Add a custom class to the container
                            }
                        })
                        setPostOpen(false)
                        fetchReset()
                        setLoading(false);
                        // setTableData((prevData) => prevData.filter(item => item.fro_id !== postObj.fro_id));

                    }
                } catch (error) {
                    console.log(error)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    icon: 'error',
                    confirmButtonColor: '#00669e',
                    customClass: {
                        container: 'custom-swal-container' // Add a custom class to the container
                    }
                })
            }
        })
    }



    const handleChange = async (event) => {
        const files = event.target.files;

        const count = imagList.length + files.length

        if (count > 4) {
            setError('You can only upload a maximum of 4 images.');

            return;
        } else {
            setError('')
        }

        if (files.length > 0) {
            // Create an array to store image previews
            const imagePreviews = [];

            // Create an array to store the file objects
            const addImages = [];

            Array.from(files).forEach((file) => {
                imagePreviews.push(URL.createObjectURL(file)); // Generate preview URL
                addImages.push(file); // Store the file
            });

            setPostImage((prevPostImage) => [...prevPostImage, ...imagePreviews]); // Update the state with preview URLs
            setImageFiles((prevImageFiles) => [...prevImageFiles, ...addImages])

            event.target.value = ""; // Reset the input value to allow re-upload of the same file(s)
        }
    };

    const handleRemovePhoto = () => {
        setPostImage([])
        setImageFiles([])
        setImagList([])
    }

    const uploadImageCallBack = (file) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file)
            formData.append('id', adminId)

            fetch(`${process.env.REACT_APP_BACKEND_URL}/upload-file`, { // Replace with your server's image upload URL
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(result => {
                    resolve({ data: { link: result.filePath } }); // Assuming server returns an image URL
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    const handlePastedText = (text, html, editorState, setEditorState) => {
        if (html) {
            // Sanitize the pasted HTML using DOMPurify
            const cleanHTML = DOMPurify.sanitize(html, {
                ALLOWED_TAGS: ['p', 'b', 'i', 'u', 'ul', 'ol', 'li', 'br'], // Allow only certain tags
                ALLOWED_ATTR: [] // Remove all attributes
            });

            // Convert sanitized HTML to DraftJS content
            const contentStateFromHTML = ContentState.createFromBlockArray(
                convertFromHTML(cleanHTML)
            );

            // Get current content
            const currentContentState = editorState.getCurrentContent();

            // Merge current content with the new content from the pasted text
            const newContentState = Modifier.replaceWithFragment(
                currentContentState,
                editorState.getSelection(),
                contentStateFromHTML.getBlockMap()
            );

            // Update editor state with the merged content
            const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
            setEditorState(newEditorState);

            return true; // Indicate that the paste was handled
        }

        return false;
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={postOpen} fullWidth maxWidth='md'>
                <DialogTitle id='customized-dialog-title' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 0, paddingTop: 2.5 }}>
                    <Box sx={{ width: 40, height: 40 }}> </Box>
                    <Typography variant='' sx={{ textAlign: 'center' }}>Edit post</Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{}}
                    >

                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{}}>
                    <Box sx={{ paddingTop: "5px" }}>

                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            placeholder='Post...'
                            toolbar={{
                                options: ['inline', 'list', 'textAlign', 'image', 'history'],
                                inline: {
                                    inDropdown: true, // Enable dropdown for inline options
                                    options: ['bold', 'italic', 'underline'],
                                },
                                list: {
                                    inDropdown: true, // Enable dropdown for list options
                                    options: ['unordered', 'ordered'],
                                },
                                textAlign: {
                                    inDropdown: true, // Enable dropdown for text alignment options
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                image: {
                                    uploadCallback: uploadImageCallBack, // Ensure this is correct
                                    urlEnabled: false, // Set to false if you don't want users to input an image URL
                                    uploadEnabled: true, // Ensure upload is enabled
                                    alignmentEnabled: true, // Allow image alignment
                                    previewImage: true, // Optionally show image preview
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg', // Accepted file types
                                    alt: { present: false, mandatory: false }, // Alternative text
                                },

                            }}
                            wrapperStyle={{
                                minHeight: '350px',
                                border: '2px solid black',
                                borderRadius: '8px',
                                padding: '10px',
                                backgroundColor: '#f5f5f5',
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            }}
                            handlePastedText={(text, html) => handlePastedText(text, html, editorState, setEditorState)}
                        />

                        {postImage.length == 1 ? (
                            <Box sx={{ width: '100%', paddingBottom: '56.25%', mt: 1, borderRadius: 1, backgroundColor: '', position: 'relative', overflow: 'hidden' }}>
                                <IconButton
                                    aria-label='close'
                                    onClick={handleRemovePhoto}
                                    sx={{ top: 0, right: 0, position: 'absolute', backgroundColor: 'gray', zIndex: 10, ":hover": { backgroundColor: 'gray' }, }}
                                >

                                    <CloseIcon />
                                </IconButton>
                                <img
                                    src={postImage[0]}
                                    alt='post'
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'contain', borderRadius: 1 }} // Ensure image fills the container
                                />
                            </Box>
                        ) : postImage.length > 1 ? (
                            <Box sx={{ width: '100%', mt: 1, borderRadius: 1, backgroundColor: '', position: 'relative', overflow: 'hidden' }}>
                                <IconButton
                                    aria-label='close'
                                    onClick={handleRemovePhoto}
                                    sx={{ top: 0, right: 0, position: 'absolute', backgroundColor: 'gray', zIndex: 10, ":hover": { backgroundColor: 'gray' }, }}
                                    size="small"
                                >

                                    <CloseIcon />
                                </IconButton>

                                <Grid container rowSpacing={1} columnSpacing={2}>
                                    {postImage.map((item, index) => (
                                        <Grid item xs={12} sm={6} key={index} >
                                            <img
                                                src={item}
                                                alt={index}
                                                loading="lazy"
                                                style={{ objectFit: 'cover', width: '100%', height: 250, cursor: 'pointer' }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ) : null}


                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start', sm: 'center' }, justifyContent: 'space-between', gap: 2, mt: 2 }}>
                            <Box sx={{ display: 'flex', gap: 2 }}>

                                <IconButton component='label' variant='contained' htmlFor='account-settings-upload-image' sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    boxShadow: '0 0px 6px 1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                                    borderRadius: 0.5,
                                    mt: 0
                                }}>
                                    <AddPhotoAlternateIcon color="success" sx={{ marginRight: '4px' }} />
                                    Image
                                    <input
                                        hidden
                                        type='file'
                                        accept='image/png, image/jpeg'
                                        multiple
                                        onChange={handleChange}
                                        id='account-settings-upload-image'
                                    />
                                </IconButton>
                                <Box sx={{ color: "#ffa500", display: 'flex', alignItems: 'center', fontSize: 14 }}>
                                    <ErrorOutlineIcon sx={{ marginRight: '4px' }} />
                                    Only 4 images
                                </Box>
                            </Box>

                            <Box sx={{ flexGrow: 1, textAlign: { sm: 'right' } }}>
                                {/* <Button variant='contained' sx={{  }} onClick={handleSubmit}>
                                    Submit
                                </Button> */}
                                <Button
                                    variant='contained'
                                    sx={{ mt: 0,
                                        "&.Mui-disabled": {
                                            backgroundColor: "#5BB4E4",
                                            color: "white",
                                          },
                                     }} 
                                    
                                    onClick={handleSubmit}
                                    disabled={loading}
                                    startIcon={loading ? (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }} />
                                    ) : null}

                                >
                                    {loading ? "Please Wait" : "Submit"}
                                </Button>
                            </Box>
                        </Box>

                        {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
                            <IconButton component='label' variant='contained' htmlFor='account-settings-upload-image' sx={{ fontSize: 16, fontWeight: 600, boxShadow: '0 0px 6px 1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)', borderRadius: '5px', mt: 0 }}>

                                <AddPhotoAlternateIcon color="success" sx={{ marginRight: '4px' }} />
                                Image

                                <input
                                    hidden
                                    type='file'
                                    accept='image/png, image/jpeg'
                                    multiple
                                    onChange={handleChange}
                                    id='account-settings-upload-image'
                                />
                            </IconButton >
                            <Box style={{ color: "#ffa500", display: 'flex', alignItems: 'center', fontSize: 14 }}>

                                <ErrorOutlineIcon sx={{ marginRight: '4px' }} />
                                Only 4 images
                            </Box>
                        </Box> */}

                        {error && (
                            <Typography variant="caption" display="block" color="error" gutterBottom sx={{ mt: 0.5 }}>
                                {error}
                            </Typography>
                        )}

                        {/* <Box sx={{ textAlign: 'right' }}>

                            <Button variant='contained' sx={{ mt: 4 }} onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Box> */}
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default EditPost