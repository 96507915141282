import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Avatar } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import List from "@mui/material/List";
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import MuiAvatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import CloseIcon from "@mui/icons-material/Close";
import AttachmentIcon from '@mui/icons-material/Attachment';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Icon } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";

const MessageProfile = ({
  tableData,
  handleProfile,
  selectedRoom,
  leftSidebarOpen,
  handleLeftSidebarToggle,
}) => {
  const theme = useTheme();

  const smAbove = useMediaQuery(theme.breakpoints.up("sm"));
  const sidebarWidth = smAbove ? 370 : 300;
  const mdAbove = useMediaQuery(theme.breakpoints.up("md"));
  const hidden = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!mdAbove) {
      handleLeftSidebarToggle()
    }
  }, [])

  const handleDashboard = () => {
    navigate("/dashboard")
  };


  const statusObj = {
    busy: "error",
    away: "warning",
    online: "success",
    offline: "secondary",
  };

  const timeformat = (date) => {
    const inputDate = new Date(date);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    // Check if the date is today
    if (inputDate.toDateString() === today.toDateString()) {
      return new Date(inputDate).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    }

    // Check if the date is yesterday
    if (inputDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    }

    // For any other date, return the date and month
    const options = { day: "numeric", month: "long" }; // Example: 1 January
    return inputDate.toLocaleDateString(undefined, options);
  };

  return (
    <div>
      <Drawer
        open={leftSidebarOpen}
        onClose={handleLeftSidebarToggle}
        variant={mdAbove ? "permanent" : "temporary"}
        ModalProps={{
          disablePortal: true,
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          zIndex: 7,
          height: "100%",
          display: "block",
          position: mdAbove ? "static" : "absolute",
          "& .MuiDrawer-paper": {
            boxShadow: "none",
            overflow: "hidden",
            width: sidebarWidth,
            position: mdAbove ? "static" : "absolute",
            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
            borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
          },
          "& > .MuiBackdrop-root": {
            borderRadius: 1,
            position: "absolute",
            zIndex: (theme) => theme.zIndex.drawer - 1,
          },
        }}
      >
        <Box sx={{ height: `calc(100vh - 170px)` }}>
          <Box
            sx={{
              maxHeight: "100%", overflowY: "auto", overflowX: "hidden",
              "&::-webkit-scrollbar": {
                width: "8px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#c1c1c1", // Scrollbar color
                borderRadius: "10px", // Rounded corners for the scrollbar
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#a39b9b", // Darker color on hover
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#F5F5F5", // Track color
              },
            }}
          >
            <Box sx={{ p: '1.25rem 0.75rem 0.75rem ' }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                  ml: 2,
                  mb: 3,
                }}
              >
                <Box sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}>
                  <IconButton onClick={handleDashboard} >
                    <KeyboardBackspaceIcon />
                  </IconButton>
                  <Typography variant="h6" sx={{ color: "primary.main" }}>
                    Messages
                  </Typography>
                </Box>

                {!mdAbove ? (
                  <IconButton
                    sx={{ p: 1, mr: 2 }}
                    onClick={handleLeftSidebarToggle}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </Box>

              <List sx={{ mb: 7.5, p: 0 }} onClick={handleLeftSidebarToggle}>
                {tableData.map((chat, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ "&:not(:last-child)": { mb: 1.5 } }}
                  >
                    <ListItemButton
                      disableRipple
                      onClick={() => handleProfile(chat)}
                      sx={{
                        px: 2,
                        py: 1.5,
                        width: "100%",
                        borderRadius: 1,
                        alignItems: "flex-start",
                        ...(chat.id == selectedRoom && {
                          background:
                            "linear-gradient(to right , #101847, #00669e)",
                        }),
                      }}
                    >
                      <ListItemAvatar sx={{ m: 0 }}>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          {chat.profile ? (
                            <MuiAvatar
                              src={chat.profile}
                              alt={`${chat.stu_fname} ${chat.stu_lname}`}
                              sx={{
                                width: 40,
                                height: 40,
                                outline: (theme) =>
                                  `2px solid ${chat.id == selectedRoom
                                    ? theme.palette.common.white
                                    : "transparent"
                                  }`,
                              }}
                            />
                          ) : chat.t_id == 0 ? (
                            <MuiAvatar
                              src='/images/et_logo.png'
                              alt='admin'
                              sx={{
                                width: 40, height: 40,

                              }}
                            />
                          ) : (
                            <Avatar
                              skin={
                                chat.id == selectedRoom
                                  ? "light-static"
                                  : "light"
                              }
                              sx={{
                                width: 40,
                                height: 40,
                                fontSize: "1rem",
                                outline: (theme) =>
                                  `2px solid ${chat.id == selectedRoom
                                    ? theme.palette.common.white
                                    : "transparent"
                                  }`,
                                textTransform: "uppercase",
                              }}
                            >
                              {`${chat.stu_fname} `}
                            </Avatar>
                          )}
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          my: 0,
                          ml: 1,
                          mr: 1.5,
                          "& .MuiTypography-root": {
                            ...(chat.id == selectedRoom && {
                              color: "common.white",
                            }),
                          },
                        }}
                        primary={
                          <Typography
                            noWrap
                            sx={{
                              ...(chat.id != selectedRoom
                                ? { color: "text.secondary" }
                                : {}),
                              textTransform: "capitalize",
                            }}
                          >
                            {chat.t_id == 0 ? 'EnglishTeacher.lk' : `${chat.t_fname} ${chat.t_lname}`}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              ...(chat.id != selectedRoom && {
                                color: "text.disabled",
                              }),
                            }}
                          >
                            {chat.last_msg !== 'last_msg_file' ? chat.last_msg : <AttachmentIcon />}
                          </Typography>
                        }
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "0.70rem",
                            whiteSpace: "nowrap",
                            color:
                              chat.id == selectedRoom
                                ? "common.white"
                                : "text.disabled",
                          }}
                        >
                          {chat.datetime
                            ? timeformat(chat.datetime)
                            : ""}
                        </Typography>
                        {chat?.stu_unread && chat?.stu_unread > 0 ? (
                          <Chip
                            color="error"
                            label={chat?.stu_unread}
                            sx={{
                              mt: 0.5,
                              height: 18,
                              fontWeight: 600,
                              fontSize: "0.75rem",
                              "& .MuiChip-label": { pt: 0.25, px: 1.655 },
                            }}
                          />
                        ) : null}
                      </Box>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default MessageProfile;
