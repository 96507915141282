import React from 'react';
import { useEffect } from 'react';
import AllTickets from '../../components/tickets/AllTickets';
import {
  Container,
  Card,
  CardContent,
  Grid,
  CardHeader,
  Typography,
} from '@mui/material';
import NavbarLog from '../../components/navbarlogged';
import TempSidebar from '../../components/TempSidebar';
import Footer from '../../components/footer'; import { Helmet } from "react-helmet";
import themeConfig from '../../config/themeConfig';


const TicketPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Tickets - {themeConfig.templateName}</title>
        <meta name="description" content="This is the Support Tickets page of your site." />
      </Helmet>
      <NavbarLog />
      <Container sx={{ py: 3, my: 4, minHeight:'100vh' }}>
        <Card sx={{ my: 3,boxShadow:{xs:0, md:"rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;"} }}>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }} >
            <Grid container spacing={2} sx={{ mb: 2, backgroundColor: '' }}>



              <Grid item xs={12} md={3.2} sx={{}}>
                <TempSidebar />
              </Grid>

              <Grid item xs={12} md={8.8} >
                <Card sx={{ mb: 1, width: "100%" }}>
                  
                  <CardContent sx={{p:0}}>
                    <AllTickets />
                  </CardContent>
                </Card>

              </Grid>

            </Grid>
          </CardContent>
        </Card>

      </Container>
      <Footer />
    </div>
  )
}

export default TicketPage
