import React from 'react'
import { Typography , Box, Container,Paper } from '@mui/material'
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import NavbarLog from '../components/navbarlogged';

const TeacherAgreement = () => {
  const studentID = localStorage.getItem('studentID');

  return (
    <div>
      <Helmet>
        <title>Teacher Agreement - {themeConfig.templateName}</title>
        <meta name="description" content="Teacher Agreement page for EnglishTeacher.lk" />
      </Helmet>

      {studentID? <NavbarLog/> :  <Navbar/> }

      <Container maxWidth="lg" sx={{  my: 10,display: "flex", flexDirection: 'column', alignItems: 'left', backgroundColor :'#eee',maxWidth:"lg", minHeight:'100vh' }}>
    
          <Typography variant="h4" gutterBottom align="center" marginTop={2}>
            Teacher Agreement
          </Typography>

          <Box mb={4}>
            <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
              In certain sections of this document, the “first party” refers to Avic Media Solution (Pvt) Ltd, a company duly incorporated and registered under registration number PV00247429 in accordance with the provisions of the Companies Act No. 7 of 2007. Its registered office is located at 33/C/2, Pinhena Junction, Kottawa, Pannipitiya.
            </Typography>
            <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
              In other sections of this agreement, the “second party” refers to the one who joins as a part-time teacher to provide teaching services under the EnglishTeacher.lk program, a project of Avic Media Solution (Pvt) Ltd.
            </Typography>
          </Box>

          <Typography variant="h6" gutterBottom >
            1. General Agreement
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The second party hereby agrees that all monetary charges, coordination, and promotional activities associated with EnglishTeacher.lk will be managed by the first party, Avic Media Solution (Pvt) Ltd.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. Hourly Fee and Commission
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            Both parties agree that the hourly fee for a class can be decided by the second party, with the first party charging a 30% commission on the total amount. The first party will also handle all payment transactions to the second party. (Currently, the minimum fee per student per hour is set at LKR 1000 by the first party.)
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. Payment Schedule
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            Payments to the second party, based on the completed number of classes, will be made at the end of the month by the first party. (That is, payments will be issued on the 30th or 31st of each month.)
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Student Ownership
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The second party agrees that they hold no ownership over students, as all student ownership remains with the first party. The second party is also not permitted to teach these students outside the first party’s platform. Additionally, private contact with students is prohibited, including sharing phone numbers or email addresses. Communication should only occur through the message feature on our website.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. Video Requirement
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The second party agrees to provide at least 10 videos for the first party's YouTube channel.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. Promotional Efforts
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            Since the first party will be handling the promotional efforts, the second party agrees to work with the first party for a minimum period of six months after the promotions begin.
          </Typography>

          <Typography variant="h6" gutterBottom>
            7. Withdrawal Notice
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            Should the second party wish to withdraw from Avic Media Solution (Pvt) Ltd, they must give at least one month’s notice to the first party. However, the first party reserves the right to terminate the agreement without notice at its discretion.
          </Typography>

          <Typography variant="h6" gutterBottom>
            8. Video Rights on Withdrawal
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            If the second party withdraws from the first party, they will have no right to remove any videos, and all rights to the videos will belong to Avic Media Solution (Pvt) Ltd.
          </Typography>

          <Typography variant="h6" gutterBottom>
            9. Video Usage
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The videos provided by the second party may be used across social media and promotional channels, and the second party agrees not to exert any pressure on the first party regarding this usage.
          </Typography>

          <Typography variant="h6" gutterBottom>
            10. Video Usage Decision
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The decision on whether or not to use the videos for promotion remains solely with the first party.
          </Typography>

          <Typography variant="h6" gutterBottom>
            11. Sharing Videos
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The second party may share videos from the EnglishTeacher.lk YouTube channel, but they are not permitted to download and distribute them elsewhere without the first party’s permission.
          </Typography>

          <Typography variant="h6" gutterBottom>
            12. Liability
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The first party is not liable for any content in the videos or the reputation of the second party.
          </Typography>

          <Typography variant="h6" gutterBottom>
            13. Recording of Classes
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            All classes conducted by the second party will be auto-recorded and monitored by the first party. The recordings will only be shared with the relevant student and the second party.
          </Typography>

          <Typography variant="h6" gutterBottom>
            14. Equipment Responsibility
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The second party acknowledges that any equipment required for teaching, including laptops and internet connections, is to be provided by the second party themselves. The first party will not be responsible for providing any such equipment.
          </Typography>

          <Typography variant="h6" gutterBottom>
            15. Class Cancellations
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            If the second party cannot conduct a scheduled class due to personal reasons, they must promptly inform our customer service team in advance.
          </Typography>

          <Typography variant="h6" gutterBottom>
            16. Dissatisfaction and Withdrawal
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            If a student is dissatisfied with the second party’s teaching during their first class, they may withdraw within 30 minutes, in which case the second party will not be entitled to any fees from that student.
          </Typography>

          <Typography variant="h6" gutterBottom>
            17. Reputation and Conduct
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            As a teacher, the second party must act in a manner that upholds both their own reputation and that of EnglishTeacher.lk, ensuring that the teaching does not harm the platform’s integrity.
          </Typography>

          <Typography variant="h6" gutterBottom>
            18. Compliance with Rules
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The second party further agrees to abide by all decisions and rules set forth by Avic Media Solution (Pvt) Ltd, and acknowledges that any violation of this agreement will result in the termination of this contract, with all legal rights reserved to the first party, Avic Media Solution (Pvt) Ltd.
          </Typography>

          <Typography variant="h6" gutterBottom>
            19. Final Decision Authority
          </Typography>
          <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
            The second party cannot act against the company’s management decisions, as the management’s decision is final.
          </Typography>

          <Box mt={4}>
            <Typography variant="body1" paragraph sx={{fontFamily:'inherit'}}>
              Since the promotional videos are broadcast locally and internationally, it is expected that the second party will maintain a respectful public image.
            </Typography>
          </Box>
      </Container>

      <Footer/>
    </div>
  )
}

export default TeacherAgreement
