import React from "react";
import {
  Container,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  Avatar,
  Typography,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Box,
  Select,
  TextField,
  Tooltip,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Navbar from "../components/navbar";
import NavbarLog from "../components/navbarlogged";
import Footer from "../components/footer";
import Common from "../components/common_part";
import { useEffect, useState } from "react";
import TeacherCard from "../components/teachercard";
import Star from "../components/star";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useNavigate } from "react-router-dom";
import ForumMain from "../components/forum_details/ForumMain";
import ForumMain1 from '../components/common_forum/ForumMain';
import { Hidden } from "@mui/material";
import { Helmet } from "react-helmet";
import themeConfig from '../config/themeConfig';
import useLogout from '../hooks/logouthook';
import UserDetails from "../components/loginPage/UserDetails";
import UserEmailDetails from "../components/loginPage/UserEmailDetails";


function ForumLogged() {
  const [studentTemp, setStudentTemp] = useState(0)
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [navbarPhonedetailsOpen, setNavbarPhonedetailsOpen] = useState(false)
  const navigate = useNavigate();

  const sessionID = localStorage.getItem('studentID');
  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  const { handleLogout } = useLogout()

  useEffect(() => {
    fetchTemp()
  }, [])

  const fetchTemp = async () => {
    try {
      const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
        method: "POST",
        body: JSON.stringify({ studentID: sessionID }),
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
      });

      if (studentResponse.status === 403 || studentResponse.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return fetchTemp();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      if (!studentResponse.ok) {
        throw new Error(`HTTP error: Status ${studentResponse.status}`);
      }

      const studentData = await studentResponse.json();
      if (studentData.temp == 1) {
        setStudentTemp(true)
      } else if (studentData.temp == 0 && studentData.phone_num) {
        setStudentTemp(false)
        setNavbarPhonedetailsOpen(true)
      } else if (studentData.temp == 0 && studentData.email) {
        setStudentTemp(false)
        setOpenUserDetails(true)
      }
       
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  }

  return (
    <div className="home-page">
      <Helmet>
        <title>Community - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
      <NavbarLog />

      <Container maxWidth="lg" sx={{ mb: 15, mt: 13, minHeight: '100vh' }}>
        {studentTemp ? ( <ForumMain /> ) : (<ForumMain1 />)}
      </Container>

      <Footer />

       
      {/* navbar email dialog */}
      <UserDetails setOpenUserDetails={setOpenUserDetails} openUserDetails={openUserDetails} />

      {/* navbar phone dialog */}
      <UserEmailDetails navbarPhonedetailsOpen={navbarPhonedetailsOpen} setNavbarPhonedetailsOpen={setNavbarPhonedetailsOpen}/>
    </div>
  );
}

export default ForumLogged;
