import React, { useEffect, useState } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Grid,
    Avatar,
    Typography,
    Box,
    Stack,
    Paper,
    Chip,
    Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

const TodayClassCard = ({ item }) => {
    const [showJoinButton, setShowJoinButton] = useState(false)
    const studentID = localStorage.getItem('studentID');
    const navigate = useNavigate('');

    useEffect(() => {
        const checkJoinButtonVisibility = () => {
            const currentDate = new Date(); // Get current date and time
            const scheduleDate = new Date(item.schedule_date);
    
            // Parse start time
            const [startHours, startMinutes, startSeconds] = item.start_time.split(':').map(Number);
            const startTime = new Date(scheduleDate);
            startTime.setHours(startHours, startMinutes, startSeconds, 0);
    
            // Calculate time difference in minutes
            const timeDiffMinutes = (startTime - currentDate) / (1000 * 60);
            
    
            // Parse end time
            const [endHours, endMinutes, endSeconds] = item.end_time.split(':').map(Number);
            const endTime = new Date(scheduleDate);
            endTime.setHours(endHours, endMinutes, endSeconds, 0);
    
            // Calculate join difference
            const joindif = (currentDate - endTime) / (1000 * 60);
    
            // Determine if the join button should be shown
            const shouldShowJoinButton = (item.class_end === 1 || item.class_end === 2) && timeDiffMinutes <= 0 && joindif < 15;
    
            // Only update if there's a change in the button visibility state
            setShowJoinButton(prevState => {
                if (prevState !== shouldShowJoinButton) {
                    return shouldShowJoinButton;
                }
                return prevState;
            });
        };
    
        // Run the check immediately
        checkJoinButtonVisibility();
    
        // Set up the interval to run the check every 60 seconds
        const intervalId = setInterval(checkJoinButtonVisibility, 60000);
    
        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [item]); // Re-run effect if 'item' changes

    const handleNavigate = (item) => {
        // Navigate to the dynamic URL with the teacher's name and ID
        navigate(`/English_Teacher_${item.t_id}-${item.t_fname}.html`);
    };

    const join_log = async (item) => {
        window.open(item.zoom_link, '_blank')
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/log_join`, {
            method: 'POST',
            body: JSON.stringify({ 
              studentID,
              ssch_id :item.ssch_id
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error: Status ${response.status}`);
          }
    
        ; 
        } catch (err) { 
        }
    };

    

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 2, py: 2, borderRadius: 2, boxShadow: 3 }}>
            <Avatar alt="User Picture" onClick={()=>handleNavigate(item)} src={item.profile} sx={{ width: 100, height: 100, border: 1, cursor: "pointer" }} />

                <Typography variant="body2" onClick={()=>handleNavigate(item)} sx={{ fontSize: '18px', mb: 2, cursor: "pointer" }}>{item.t_fname} {item.t_lname}</Typography>

            <Stack direction="row" spacing={1}>
                <Chip label="Teacher Approved" color="success" sx={{ height: 18, fontSize: 11 }} />
                {item.reschedule === 1 && (
                    <Chip label="Rescheduled" sx={{ height: 18, fontSize: 11, backgroundColor: '#CDA12E', color: 'white' }} />
                )}
            </Stack>

            <Grid container spacing={1} alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
                <Grid item sx={{ mb: 1 }}>
                    <Typography variant="body1">Class ID : {item.ssch_id}</Typography>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', background: 'linear-gradient(to right , #101847, #00669e)', p: 1 }}>
                <Stack alignItems="center">
                    <Stack direction="row" alignItems="center">
                        <Typography style={{ color: 'white', mt: 2 }}>{item.start_time} - {item.end_time}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <Typography style={{ color: 'white' }}>{new Date(item.schedule_date).toLocaleDateString('en-CA')}</Typography>
                    </Stack>
                </Stack>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90%', my: 3 }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={3.8}>
                        <Item sx={{ backgroundColor: 'rgba(91, 180, 228, 0.4)' }}>
                            <Typography>Hour</Typography>
                            <Typography>{item.timeLeft?.hours.toString().padStart(2, '0') || '00'}</Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={3.8}>
                        <Item sx={{ backgroundColor: 'rgba(91, 180, 228, 0.4)' }}>
                            <Typography>Min</Typography>
                            <Typography>{item.timeLeft?.minutes.toString().padStart(2, '0') || '00'}</Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={3.8}>
                        <Item sx={{ backgroundColor: 'rgba(91, 180, 228, 0.4)' }}>
                            <Typography>Sec</Typography>
                            <Typography>{item.timeLeft?.seconds.toString().padStart(2, '0') || '00'}</Typography>
                        </Item>
                    </Grid>
                </Grid>
            </Box>

            {showJoinButton ? (
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', px: 3 }}>
                    <Link to={`/messages/${item.t_id}/${studentID}`}>
                        <Button type="submit" size="small" variant="outlined">Any Question?</Button>
                    </Link>
                    <Button
                        type="submit"
                        variant="contained"
                        size="small"
                        sx={{ color: 'white', backgroundColor: '#0d2353' }}
                        // onClick={() => window.open(item.zoom_link, '_blank')}
                        onClick={()=>join_log(item)}

                    >
                        Join
                    </Button>
                </Grid>
            ) : (
                <Link to={`/messages/${item.t_id}/${studentID}`}>
                    <Button size="small" variant="outlined">Any Question?</Button>
                </Link>
            )}
        </Box>
    )
}

export default TodayClassCard