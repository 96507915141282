import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Box } from '@mui/material'
import Swal from 'sweetalert2'
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import useLogout from '../../hooks/logouthook'

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { EditorState, ContentState, convertFromHTML, Modifier } from 'draft-js';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { CircularProgress } from "@mui/material";


const AddPost = ({ addOpenPost, setAddOpenPost, fetchForums, triggerFileInput }) => {
    const [post, setPost] = useState('')
    const [imagePreview, setImagePreview] = useState('')
    const [addImage, setAddImage] = useState([])
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [previewList, setPreviewList] = useState([])
    const [error, setError] = useState('')
    const handleLogout = useLogout();
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate()

    let adminId = ''
    if (typeof localStorage !== 'undefined') {
        adminId = window.localStorage.getItem('studentID')
    }

    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }


    useEffect(() => {
        if (triggerFileInput && addOpenPost) {
            const timer = setTimeout(() => {
                const fileInput = document.getElementById('account-settings-upload-image');
                if (fileInput) {
                    fileInput.click();
                }
            }, 100); // Delay of 100 milliseconds

            return () => clearTimeout(timer); // Clear the timeout if the effect runs again or if the component unmounts
        }
    }, [triggerFileInput, addOpenPost])

    const handleClose = () => {
        setAddOpenPost(false)
        setError('')
    }

    const handlePost = () => {
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const contentHTML = draftToHtml(rawContent);


        setError('')
        if (addImage.length < 1 && contentHTML.length < 9) {
            setError('Please add a image or content')

            return
        }
        setPost(contentHTML)

        const formData = new FormData()
        addImage.forEach((file, index) => {
            formData.append('files', file); // 'files' is the field name expected by Multer
        });
        formData.append('title', contentHTML)
        formData.append('id', adminId)

        
        Swal.fire({
            text: 'Are you sure you want to add a post?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00669e',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
            }
        }).then(async result => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/add-post`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        body: formData
                    })
                    if (response.status == 403 || response.status == 401) {

                        let refreshToken = ''
                        if (typeof localStorage !== 'undefined') {
                            refreshToken = window.localStorage.getItem('refreshToken')
                        }

                        try {
                            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    refreshToken: refreshToken
                                })
                            })
                            if (tokenResponse.status == 200) {
                                const tokendata = await tokenResponse.json()
                                window.localStorage.setItem('token', tokendata.accessToken)
                                token = tokendata.accessToken
                                handlePost()
                            } else {
                                handleLogout();
                            }
                        } catch (error) {
                            console.error(error)
                        }
                    } else if (response.status == 200) {
                        Swal.fire({
                            title: 'Post Submitted!',
                            text: 'Your post will be visible once approved by the admin.',
                            icon: 'success',
                            confirmButtonColor: '#00669e',
                            customClass: {
                                container: 'custom-swal-container' // Add a custom class to the container
                            }
                        })
                        setAddOpenPost(false)
                        fetchForums()
                        setAddImage([])
                        setPreviewList([])
                        setPost('')
                        setEditorState(EditorState.createEmpty());
                        setLoading(false);
                    }
                } catch (error) {
                    console.log(error)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    icon: 'error',
                    confirmButtonColor: '#00669e',
                    customClass: {
                        container: 'custom-swal-container' // Add a custom class to the container
                    }
                })
            }
        })
    }


    const handleChange = async (event) => {
        const files = event.target.files;

        const count = addImage.length + files.length

        if (count > 4) {
            setError('You can only upload a maximum of 4 images.');

            return;
        } else {
            setError('')
        }

        if (files.length > 0) {
            // Create an array to store image previews
            const imagePreviews = [];

            // Create an array to store the file objects
            const addImagess = [];

            Array.from(files).forEach((file) => {
                imagePreviews.push(URL.createObjectURL(file)); // Generate preview URL
                addImagess.push(file); // Store the file
            });

            setPreviewList((prevPostImage) => [...prevPostImage, ...imagePreviews]); // Update the state with preview URLs
            setAddImage((prevImageFiles) => [...prevImageFiles, ...addImagess])

            event.target.value = ""; // Reset the input value to allow re-upload of the same file(s)
        }
    };

    const handleRemovePhoto = () => {
        setPreviewList([])
        setAddImage([])

    }

    const uploadImageCallBack = (file) => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file)
            formData.append('id', adminId)

            fetch(`${process.env.REACT_APP_BACKEND_URL}/upload-file`, { // Replace with your server's image upload URL
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(result => {
                    resolve({ data: { link: result.filePath } }); // Assuming server returns an image URL
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    const handlePastedText = (text, html, editorState, setEditorState) => {
        if (html) {
            // Sanitize the pasted HTML using DOMPurify
            const cleanHTML = DOMPurify.sanitize(html, {
                ALLOWED_TAGS: ['p', 'b', 'i', 'u', 'ul', 'ol', 'li', 'br'], // Allow only certain tags
                ALLOWED_ATTR: [] // Remove all attributes
            });

            // Convert sanitized HTML to DraftJS content
            const contentStateFromHTML = ContentState.createFromBlockArray(
                convertFromHTML(cleanHTML)
            );

            // Get current content
            const currentContentState = editorState.getCurrentContent();

            // Merge current content with the new content from the pasted text
            const newContentState = Modifier.replaceWithFragment(
                currentContentState,
                editorState.getSelection(),
                contentStateFromHTML.getBlockMap()
            );

            // Update editor state with the merged content
            const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
            setEditorState(newEditorState);

            return true; // Indicate that the paste was handled
        }

        return false;
    };

    return (
        <div>
            <Dialog onClose={handleClose} open={addOpenPost} fullWidth maxWidth='md'>

                <DialogTitle id='customized-dialog-title' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 0 }}>
                    <Box sx={{ width: 40, height: 40 }}> </Box>
                    <Typography variant='' sx={{ textAlign: 'center' }}>Ask a question</Typography>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ minHeight: 450 }}>
                    <Box sx={{ paddingTop: "5px" }}>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={setEditorState}
                            placeholder='Ask...'
                            toolbar={{
                                options: ['inline', 'list', 'textAlign', 'image', 'history'],
                                inline: {
                                    inDropdown: true, // Enable dropdown for inline options
                                    options: ['bold', 'italic', 'underline'],
                                },
                                list: {
                                    inDropdown: true, // Enable dropdown for list options
                                    options: ['unordered', 'ordered'],
                                },
                                textAlign: {
                                    inDropdown: true, // Enable dropdown for text alignment options
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                image: {
                                    uploadCallback: uploadImageCallBack, // Ensure this is correct
                                    urlEnabled: false, // Set to false if you don't want users to input an image URL
                                    uploadEnabled: true, // Ensure upload is enabled
                                    alignmentEnabled: true, // Allow image alignment
                                    previewImage: true, // Optionally show image preview
                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg', // Accepted file types
                                    alt: { present: false, mandatory: false }, // Alternative text
                                },
                            }}
                            wrapperStyle={{
                                minHeight: '350px',
                                border: '2px solid black',
                                borderRadius: '8px',
                                padding: '10px',
                                backgroundColor: '#f5f5f5',
                                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            }}
                            handlePastedText={(text, html) => handlePastedText(text, html, editorState, setEditorState)}
                        />

                        {previewList.length === 1 ? (
                            <Box sx={{ width: '100%', paddingBottom: '56.25%', mt: 2, borderRadius: 1, position: 'relative', overflow: 'hidden' }}>
                                <IconButton
                                    aria-label='close'
                                    onClick={handleRemovePhoto}
                                    sx={{ top: 0, right: 0, position: 'absolute', backgroundColor: 'gray', zIndex: 10, ":hover": { backgroundColor: 'gray' } }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <img
                                    src={previewList[0]}
                                    alt='post'
                                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'contain', borderRadius: 1 }} // Ensure image fills the container
                                />
                            </Box>
                        ) : previewList.length > 1 ? (
                            <Box sx={{ width: '100%', height: previewList.length > 2 ? 500 : 250, mt: 2, borderRadius: 1, position: 'relative', overflow: 'hidden' }}>
                                <IconButton
                                    aria-label='close'
                                    onClick={handleRemovePhoto}
                                    sx={{ top: 0, right: 0, position: 'absolute', backgroundColor: 'gray', zIndex: 10, ":hover": { backgroundColor: 'gray' } }}
                                >
                                    <CloseIcon />
                                </IconButton>

                                <Grid container rowSpacing={1} columnSpacing={2}>
                                    {previewList.map((item, index) => (
                                        <Grid item xs={12} sm={6} key={index}>
                                            <img
                                                src={item}
                                                alt={index}
                                                loading="lazy"
                                                style={{ objectFit: 'cover', width: '100%', height: 250, cursor: 'pointer' }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        ) : null}

                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start', sm: 'center' }, justifyContent: 'space-between', gap: 2, mt: 2 }}>
                            <Box sx={{ display: 'flex', gap: 2 }}>

                                <IconButton component='label' variant='contained' htmlFor='account-settings-upload-image' sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    boxShadow: '0 0px 6px 1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
                                    borderRadius: 0.5,
                                    mt: 0
                                }}>
                                    <AddPhotoAlternateIcon color="success" sx={{ marginRight: '4px' }} />
                                    Image
                                    <input
                                        hidden
                                        type='file'
                                        accept='image/png, image/jpeg'
                                        multiple
                                        onChange={handleChange}
                                        id='account-settings-upload-image'
                                    />
                                </IconButton>
                                <Box sx={{ color: "#ffa500", display: 'flex', alignItems: 'center', fontSize: 14 }}>
                                    <ErrorOutlineIcon sx={{ marginRight: '4px' }} />
                                    Only 4 images
                                </Box>
                            </Box>

                            <Box sx={{ flexGrow: 1, textAlign: { sm: 'right' } }}>
                                <Button
                                    variant='contained'
                                    sx={{ mt: 0,
                                        "&.Mui-disabled": {
                                            backgroundColor: "#5BB4E4",
                                            color: "white",
                                          },
                                     }} 
                                    endIcon={<SendIcon color="white" />}
                                    onClick={handlePost}
                                    disabled={loading}
                                    startIcon={loading ? (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }} />
                                    ) : null}

                                >
                                    {loading ? "Please Wait" : "Submit to Approve"}
                                </Button>
                            </Box>
                        </Box>

                        {error && (
                            <Typography variant="caption" display="block" color="error" gutterBottom sx={{ mt: 1 }}>
                                {error}
                            </Typography>
                        )}
                    </Box>
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default AddPost