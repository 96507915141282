import React from 'react';
import { useState, useEffect } from 'react';
import { Button, useMediaQuery } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
// import BlankLayout from 'src/@core/layouts/BlankLayout'
import { Container, Card, Typography,  Box } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {useTheme} from '@mui/material';


const TeacherEmailVerify = () => {

    const [email, setEmail] = useState('')
    const navigate = useNavigate('');
    const [error, setError] = useState("");
    const [isVerified, setIsVerified] = React.useState(false);
    const theme = useTheme();

    useEffect(() => {
        handleSubmit()
      }, []);
    
      const [searchParams, setSearchParams] = useSearchParams();

      const handleSubmit = async () => {
         
        try {
            
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/change_teacher_verification_status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: searchParams.get("token"), 
                    
                })
            });
    
            const data = await response.json(); 
    
            if (response.status === 200) {
                setIsVerified(true);
                Swal.fire({
                    text: data.message,
                    icon: 'success',
                    confirmButtonColor: '#00669e',
                    customClass: {
                        container: 'custom-swal-container' 
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.href = 'https://teacher.englishteacher.lk/';
                    }
                  });
              
                
            } else {
                
                Swal.fire({
                    text: data.message,
                    icon: 'error', 
                    confirmButtonColor: '#00669e',
                    customClass: {
                        container: 'custom-swal-container' 
                    }
                });
            }
    
        } catch (error) {
            console.error('Error fetching data:', error.message); 
           
        }
    };
    


  return (
    <div>
    <Container
        maxWidth={false}
        disableGutters
        sx={{
            minHeight: '100vh',
            backgroundImage: 'url("images/emailverify2.png")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            // color: '#fff', 
            textAlign: 'center',
            position: 'relative'
        }}
    >

        <Box
            sx={{
                padding: theme.spacing(4),
                backdropFilter: 'blur(6px)',

            }}
        >
            {/* Icon: Success or Failure */}
            {isVerified ? (
                <CheckCircleOutlineIcon color="success" sx={{ fontSize: 80, mb: 2 }} />
            ) : (
                <ErrorOutlineIcon color="error" sx={{ fontSize: 80, mb: 2 }} />
            )}

            {/* Heading */}
            <Typography variant="h4" component="h1" gutterBottom>
                {isVerified ? 'Email Verified!' : 'Verification Failed'}
            </Typography>

            {/* Subtext */}
            <Typography variant="body1" sx={{ mb: 4 }}>
                {isVerified
                    ? 'Your email has been successfully verified. You can now proceed to the dashboard.'
                    : 'The email verification link is either invalid or has expired. Please try again or contact support.'}
            </Typography>

            {/* Button */}
            {/* <Button
                variant="contained"
                color="primary"
                size="large"
                href={isVerified ? "/dashboard" : "/resend-verification"}
            >
                {isVerified ? 'Go to Dashboard' : 'Resend Verification Email'}
            </Button> */}
        </Box>
        <Box
            sx={{
                maxWidth: 400,

            }}
        >
            <img
                src={'/images/emailverify.png'}
                alt='welcome'
                style={{ width: "100%", height: 'auto', objectFit: 'contain' }}
            />
        </Box>
    </Container>
</div>
  )
}

export default TeacherEmailVerify
