import React from 'react';
import {
  Container,
  Card,
  CardContent, 
  Grid, 
  Avatar, 
  Typography, 

} from '@mui/material';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
// import SideMenu from '../components/sidemenu';
import Common from '../components/common_part';
import { useEffect, useState } from 'react';
import UnpaidCard1 from '../components/unpaidcard';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';


function UnpaidClasses() {

  const [stu_Data, setStu_Data] = useState({});
  const studentID = localStorage.getItem('studentID');
  
  

  return (
    <div className="home-page">
      <Helmet>
        <title>Unpaid Classes - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
      </Helmet>
        <NavbarLog/>
        <Container sx={{py:3, mt:4}}>
          <Card sx={{my:3}}>
              <CardContent sx={{display:'flex', justifyContent: 'center'}} >
                <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap:1 }}>

                 
                  <Common/>

                  <Grid xs={12} md={8.8} sx={{mb:2}}>
                    <UnpaidCard1/>
                  </Grid>
                </Grid>
              </CardContent>
          </Card>
          
        </Container>
        <Footer/>
    </div>
  );
}

    
    export default UnpaidClasses;