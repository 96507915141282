
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const SessionChecker = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    

    useEffect(() => {
        const publicRoutes = ["/teacher_signup","/teacher_agreement", "/community","/community_knowledge","/privacy_policy","/T_C" ,"/*","/forgot_password", "/reset-password","/email_verify", "/teacher_emailverification", "/student_emailverification","/email-unsubscribe","/rate-class" ];
        const dynamicRoutes = [
            /^\/English_Teacher_\d+-[a-zA-Z]+\.html$/, // Existing dynamic route
            /^\/community\/\d+\/([^\/]+)?$/  // Updated route for /community/:id/:title?
        ];

        const isPublicRoute = publicRoutes.includes(location.pathname);
        const isDynamicRoute = dynamicRoutes.some((pattern) => pattern.test(location.pathname));

        // Check if the current path is in the list of public routes
        if (isPublicRoute || isDynamicRoute) {
            setLoading(false); // Skip session check for public routes
        } else {
            const localId = localStorage.getItem("studentID");
            if (!localId) {
                navigate("/");
                setLoading(false); // Redirect if local is not available
            } else {
                setLoading(false); // Stop loading once session ID is confirmed
            }
        }
    }, [navigate, location.pathname]); // Also add location.pathname to dependencies

    return (
        <>
            {loading ? (
                <Box
                    style={{
                        height: "100vh",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress disableShrink />
                </Box>
            ) : (
                children
            )}
        </>
    );
};

export default SessionChecker;

