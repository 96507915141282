import React from 'react';
import {
  Container,
  Card,
  CardContent, 
  Grid, 
  Avatar, 
  Typography, 

} from '@mui/material';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import Common from '../components/common_part';
import TodayCard1 from '../components/todaycard1';
import { useEffect, useState } from 'react';
import TeacherCard from '../components/teachercard';
import Star from '../components/star';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { Hidden } from '@mui/material';
import {Helmet} from "react-helmet";
import themeConfig from '../config/themeConfig';



function TodayClass() {

  const [stu_Data, setStu_Data] = useState({});
  const studentID = localStorage.getItem('studentID');
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


 
  
  return (
    <div className="home-page">
      <Helmet>
        <title>Today Classes - {themeConfig.templateName}</title>
        <meta name="description" content="This is the Today Classes page of your site." />
      </Helmet>
        <NavbarLog/>
        <Container sx={{py:3, my:4, minHeight:'100vh'}}>
          <Card sx={{my:3, boxShadow:{xs:0, md:"rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;"}}}>
              <CardContent sx={{display:'flex', justifyContent: 'center'}} >
                <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap:1 }}>

                <Hidden smDown>
                    <Common />
                  </Hidden>

                <Grid xs={12} md={8.8} sx={{mb:2}}>
                <TodayCard1/>


                </Grid>
                </Grid>
              </CardContent>
          </Card>
          
        </Container>
        <Footer/>
    </div>
  );
}

    
    export default TodayClass;