import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Button,
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Grid,
} from "@mui/material";
import Swal from 'sweetalert2';
import MenuIcon from "@mui/icons-material/Menu";
import GroupsIcon from "@mui/icons-material/Groups";
import "@fontsource/roboto/500.css";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import { NativeSelect } from '@mui/material';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import useLogout from "../../hooks/logouthook";
import { CircularProgress } from '@mui/material';

function CustomPopper(props) {
  return <Popper {...props} placement="top-start" />;
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '5px solid #00669e',
  },
}));
const LoggedUserDetails = ({setOpenLoggedUserDetails, openLoggedUserDetails}) => {
    const [stu_fname, setStu_fname] = useState("");
    const [stu_lname, setStu_lname] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("Sri Lanka");
    const [openProfileModal, setOpenProfileModal] = useState(false);
    const [errors, setErrors] = useState("");
    const studentID = localStorage.getItem('studentID');
    const navigate = useNavigate();
    const [mobileError, setMobileError] = useState("");
    const [loading, setLoading] = React.useState(false);
  
    const { handleLogout } = useLogout()
  
    let token = ''
    if (typeof localStorage !== 'undefined') {
      token = window.localStorage.getItem('token')
    }
  
    const handlefirstname = (event) => {
      setStu_fname(event.target.value);
      setErrors("")
    };
  
    const handlelastname = (event) => {
      setStu_lname(event.target.value);
      setErrors("")
    };
  
    //   const handlePhone = (event) => {
    //     const valuemob = event.target.value;
    //     setPhone(valuemob);
    // };
  
    const handlePhone = (event) => {
      let valuemob = event.target.value;
      setErrors({ phone: "" }); // Initialize the errors state
      setMobileError("");
  
      if (!valuemob) {
        setErrors({ phone: "" }); // Clear errors if input is empty
      } else if (!/^\+?[\d]+$/.test(valuemob)) {
        setErrors({ phone: "Invalid mobile number. Only digits and an optional '+' are allowed." });
      } else {
        if (valuemob.startsWith("7")) {
          if (valuemob.length !== 9) {
            setErrors({ phone: "Invalid mobile number. Must be 9 digits when starting with '7'." });
          }
        } else if (valuemob.startsWith("07")) {
          if (valuemob.length !== 10) {
            setErrors({ phone: "Invalid mobile number. Must be 10 digits when starting with '07'." });
          }
        } else if (valuemob.startsWith("947")) {
          if (valuemob.length !== 11) {
            setErrors({ phone: "Invalid mobile number. Must be 11 digits when starting with '947'." });
          }
        } else if (valuemob.startsWith("+947")) {
          if (valuemob.length !== 12) {
            setErrors({ phone: "Invalid mobile number. Must be 12 digits when starting with '+947'." });
          }
        } else {
          setErrors({ phone: "Invalid mobile number format." });
        }
      }
  
      setPhone(valuemob);
    };
  
    const handleaddress = (event) => {
      setAddress(event.target.value);
      setErrors("")
    };
  
    const handlecity = (event) => {
      setCity(event.target.value);
      setErrors("")
    };
  
    const handlecountry = (event, newValue) => {
      setCountry(newValue);
      setErrors("")
    };
  
    const handleCloseProfileModal = () => {
        setOpenLoggedUserDetails(false);
      setErrors("");
    };
  
    const capitalizeFirstLetter = (string) => {
      if (!string) return "";
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
  
    const handleSubmitProfileEmail = async (event) => {
      event.preventDefault();
      
  
      let newErrors = {};
  
  
      if (!stu_fname) {
        newErrors.stu_fname = "Please enter the First Name";
      }
  
      if (!stu_lname) {
        newErrors.stu_lname = "Please enter the Last Name";
      }
  
      if (!phone) {
        newErrors.phone = "Please enter the Mobile Number";
      }
  
      if (!address) {
        newErrors.address = "Please enter the Address";
      }
  
      if (!city) {
        newErrors.city = "Please enter the City";
      }
  
      if (!country) {
        newErrors.country = "Please enter the Country";
      }
  
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      } else {
        setLoading(true);
        setErrors('');
  
        // Capitalize names
        const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
        const capitalizedLastName = capitalizeFirstLetter(stu_lname);
        const capitalizedAddress = capitalizeFirstLetter(address);
        const capitalizedCity = capitalizeFirstLetter(city);
        const capitalizedCountry = capitalizeFirstLetter(country);
  
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details_Email`, {
            method: 'POST',
            body: JSON.stringify({
              studentID,
              stu_fname: capitalizedFirstName,
              stu_lname: capitalizedLastName,
              phone,
              address: capitalizedAddress,
              city: capitalizedCity,
              country: capitalizedCountry
            }),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
  
            }
          });
  
          if (response.status === 403 || response.status === 401) {
            let refreshToken = '';
            if (typeof localStorage !== 'undefined') {
              refreshToken = window.localStorage.getItem('refreshToken');
            }
  
            try {
              console.log("object")
              const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  refreshToken: refreshToken,
                }),
              });
  
              if (tokenResponse.status === 200) {
                setLoading(false);
                const tokendata = await tokenResponse.json();
                window.localStorage.setItem('token', tokendata.accessToken);
                token = tokendata.accessToken;
                return handleSubmitProfileEmail(event);
                
              } else {
                setLoading(false);
                handleLogout();
              }
            } catch (error) {
              setLoading(false);
              console.error('Error refreshing token:', error);
              handleLogout();
            }
            setLoading(false);
            return;
          }
  
  
          const data = await response.json();
  
          if (response.status === 200) {
            if (window.gtag_report_conversion) {
              console.log("check script")
              window.gtag_report_conversion();
            } else {
              console.warn("gtag_report_conversion function is not defined.");
            }
            setStu_fname("");
            setStu_lname("");
            setPhone("");
            setAddress("");
            setCity("");
            setCountry("");
            setErrors('');
            handleCloseProfileModal();
            // handleOpenTimeSlotModal(t_id); 
            navigate("/dashboard");
          } else if (response.status === 400) {
            setMobileError(data.message);
  
          }
          setLoading(false);
  
        } catch (error) {
          setErrors("Failed to update profile. Please try again.");
        }
      }
    };
  
    const countries = [
      "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
      "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
      "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
      "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
      "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
      "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
      "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
      "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
      "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
      "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
      "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan",
      "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
      "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
      "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
      "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
      "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
      "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
      "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
      "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
      "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
      "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
      "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
      "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia",
      "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
      "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
      "Yemen", "Zambia", "Zimbabwe"
    ];
  
  
    return (
      <div>
        <Dialog open={openLoggedUserDetails} fullWidth>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'black', alignItems:'center' }}>
            <DialogTitle sx={{ ml: 1, fontSize:{xs:'17px', sm:'20px'} }} >Please Enter your Details</DialogTitle>
            <Tooltip title="Close">
              <CloseIcon sx={{  mr: 3, cursor: 'pointer' }} onClick={handleCloseProfileModal} />
            </Tooltip>
          </Box>
  
          <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <form
  
              style={{
                width: "100%",
                backgroundColor: "linear-gradient(to right , #101847, #00669e",
                color: "white"
              }}
              onSubmit={handleSubmitProfileEmail}
  
            >
              <Grid container spacing={1} sx={{ px: 1 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="stu_fname"
                    name="firstName"
                    label="First Name"
                    size="large"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "10px !important",
  
  
                    }}
                    fullWidth
                    value={stu_fname}
                    onChange={handlefirstname}
  
                  />
                  {errors.stu_fname ?
                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                      {errors.stu_fname}
                    </Typography> : null
                  }
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    size="large"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "10px !important",
  
                    }}
                    fullWidth
                    value={stu_lname}
                    onChange={handlelastname}
  
                  />
                  {errors.stu_lname ?
                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                      {errors.stu_lname}
                    </Typography> : null
                  }
                </Grid>
  
                <Grid item xs={12}>
                  <TextField
                    id="phone"
                    name="phone"
                    label="Mobile Number"
                    size="large"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "10px !important",
                      mt: 1
                    }}
                    fullWidth
                    value={phone}
                    onChange={handlePhone}
                  />
                  {errors.phone ?
                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                      {errors.phone}
                    </Typography> : null
                  }
                  {mobileError ?
                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                      {mobileError}
                    </Typography> : null
                  }
                </Grid >
  
                <Grid item xs={12}>
                  <TextField
                    id="address"
                    name="House No, Lane Name"
                    label="House No, Lane Name"
                    size="large"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "10px !important",
                      mt: 1
  
                    }}
                    fullWidth
                    value={address}
                    onChange={handleaddress}
  
                  />
                  {errors.address ?
                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                      {errors.address}
                    </Typography> : null
                  }
                </Grid>
  
                <Grid item xs={12}>
                  <TextField
                    id="city"
                    name="City"
                    label="City"
                    size="large"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "10px !important",
  
                    }}
                    fullWidth
                    value={city}
                    onChange={handlecity}
  
                  />
                  {errors.city ?
                    <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                      {errors.city}
                    </Typography> : null
                  }
                </Grid>
  
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    value={country}
                    onChange={handlecountry}
                    options={countries}
                    sx={{ width: "100%" }}
                    PopperComponent={CustomPopper} // Custom Popper to position dropdown above
                    ListboxProps={{
                      sx: {
                        maxHeight: '200px', // Set height for dropdown content
                        overflowY: 'auto', // Enable scrolling only inside the list
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "10px !important",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item
                  mb={1}
                  xs={12}
                  sx={{
  
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
  
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{color: "white",
                      backgroundColor:  "#0d2353",
                      "&:hover": {
                        backgroundColor:  "#0d65c8",
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#5BB4E4",
                        color: "white",
                      },
    }}
                    size="md"
                    disabled={loading}
                    startIcon={loading ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                          color:'#fff'
                        }} />
                    ) : null}
                  >
                    {loading ? "Please Wait" : "Sign up"}
                 
                  </Button>
  
                </Grid>
              </Grid>
            </form>
  
          </DialogContent>
        </Dialog>
  
      </div>
    )
  }

export default LoggedUserDetails
