import React from 'react';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Select,
  MenuItem,
  Avatar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Stack,
  Paper,
  Badge,
  Button,
  TextField

} from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import Common from '../components/common_part';
import { useEffect, useState } from 'react';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import useLogout from '../hooks/logouthook';
import { Helmet } from "react-helmet";
import themeConfig from '../config/themeConfig';
import { DatePicker } from '@mui/lab';
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'





const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));


function ProfileSetting() {

  const studentID = localStorage.getItem('studentID');
  const [stu_Data, setStu_Data] = useState({});
  const [update_Data, setUpdate_Data] = useState("");
  const [nicError, setNicError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [cityError, setCityError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [edit_Data, setEdit_Data] = useState({
    stu_fname: '',
    stu_lname: '',
    NIC: '',
    phone_num: '',
    email: '',
    address: '',
    city: '',
    country: '',
    gender: '',
    birthday: '',
    email_subscribe: 1
  });
  const [file, setFile] = useState("");
  const [commpleteProStatus, setCommpleteProStatus] = useState(2)
console.log(edit_Data)
  const navigate = useNavigate();
  const { handleLogout } = useLogout()

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  const oldNICPattern = /^[0-9]{9}[vVxX]$/;
  const newNICPattern = /^[0-9]{12}$/;

  <DatePicker
    label="Birthday"
    value={dayjs(edit_Data.birthday).format('YYYY-MM-DD')}
    onChange={(newValue) => setEdit_Data({ ...edit_Data, birthday: newValue })}
    maxDate={dayjs()}
    renderInput={(params) => <TextField {...params} />}
  />

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (studentID) {
      fetchEdit_Data();
      fetchProfileStatus()
    }
  }, [studentID]);

  const handleFileChange = (e) => {


    setFile(e.target.files[0]);
  };

  const fetchProfileStatus = async (profile) => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/complete_pro_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        id: studentID
      })
    });

    if (response.status == 403 || response.status == 401) {

      let refreshToken = ''
      if (typeof localStorage !== 'undefined') {
        refreshToken = window.localStorage.getItem('refreshToken')
      }

      try {
        const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            refreshToken: refreshToken
          })
        })
        if (tokenResponse.status == 200) {
          const tokendata = await tokenResponse.json()
          window.localStorage.setItem('token', tokendata.accessToken)
          token = tokendata.accessToken
          fetchProfileStatus(profile);
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      const data = await response.json();
      if (response.status === 200) {
        setCommpleteProStatus(data.result.profile_complete);
      }
    }

  }

  const fetchEdit_Data = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_setting`, {
        method: 'POST',
        body: JSON.stringify({
          studentID,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`

        }
      });

      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            fetchEdit_Data();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }


      const getData = await response.json();
      setEdit_Data({
        profile: getData && getData.profile ? getData.profile : '',
        stu_fname: getData && getData.stu_fname ? getData.stu_fname : '',
        stu_lname: getData && getData.stu_lname ? getData.stu_lname : '',
        NIC: getData && getData.NIC ? getData.NIC : '',
        phone_num: getData && getData.phone_num ? getData.phone_num : '',
        email: getData && getData.email ? getData.email : '',
        address: getData && getData.address ? getData.address : '',
        city: getData && getData.city ? getData.city : '',
        country: getData && getData.country ? getData.country : '',
        gender: getData && getData.gender ? getData.gender.toLowerCase() : '',
        birthday: getData && getData.birthday ? getData.birthday : '',
        email_subscribe: getData?.email_subscribe ?? 1,
      });
     

    } catch (err) {
      console.error(err)
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let valid = true;

    if (edit_Data.NIC) {
      if (oldNICPattern.test(edit_Data.NIC) || newNICPattern.test(edit_Data.NIC)) {
        setNicError('');
      } else {
        setNicError('Invalid NIC format. Please enter a valid NIC.');
        valid = false;
      }
    } else {
      setNicError(''); // Clear error if field is empty
    }


    // First Name validation
    if (!edit_Data.stu_fname) {
      setFirstNameError('First Name is required');
      valid = false;
    } else {
      setFirstNameError('');
    }

    // Last Name validation
    if (!edit_Data.stu_lname) {
      setLastNameError('Last Name is required');
      valid = false;
    } else {
      setLastNameError('');
    }

    // Address validation
    if (!edit_Data.address) {
      setAddressError('Address is required');
      valid = false;
    } else {
      setAddressError('');
    }

    // City validation
    if (!edit_Data.city) {
      setCityError('City is required');
      valid = false;
    } else {
      setCityError('');
    }

    // Country validation
    if (!edit_Data.country) {
      setCountryError('Country is required');
      valid = false;
    } else {
      setCountryError('');
    }

    // Only proceed if all fields are valid
    if (!valid) {
      return; // Stop the form submission if any error exists
    }

    try {
      const formData = new FormData();
      formData.append('studentID', studentID);
      formData.append('stu_fname', edit_Data.stu_fname);
      formData.append('stu_lname', edit_Data.stu_lname);
      formData.append('NIC', edit_Data.NIC);
      formData.append('phone_num', edit_Data.phone_num);
      formData.append('email', edit_Data.email);
      formData.append('address', edit_Data.address);
      formData.append('city', edit_Data.city);
      formData.append('country', edit_Data.country);
      formData.append('gender', edit_Data.gender);
      formData.append('birthday', edit_Data.birthday);

      if (file) {
        formData.append('profile', file);
      }
      console.log(formData)

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update_profile`, {
        method: 'POST',
        body: formData,
        headers: {

          Authorization: `Bearer ${token}`

        }
      });

      if (response.status == 403 || response.status == 401) {

        let refreshToken = ''
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken')
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refreshToken: refreshToken
            })
          })
          if (tokenResponse.status == 200) {
            const tokendata = await tokenResponse.json()
            window.localStorage.setItem('token', tokendata.accessToken)
            token = tokendata.accessToken
            handleSubmit(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error(error)
        }
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setUpdate_Data(getData[0]);

      if (getData.message === 'Profile updated successfully') {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Profile updated successfully!',
          confirmButtonColor: '#00669e',
          confirmButtonText: 'OK'
        });
        fetchProfileStatus()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed!',
          text: 'Sorry, there was an error!',
          confirmButtonColor: '#00669e',
          confirmButtonText: 'OK'
        });
      }
    } catch (err) {
      console.error('Error updating profile:', err);
    }

  };

  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
    "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan",
    "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
    "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
    "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
    "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia",
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
    "Yemen", "Zambia", "Zimbabwe"
  ];

  const handleEmailsubscription = () => {

    if (edit_Data.email_subscribe === 0) {
      Swal.fire({
        title: `Confirm Email Subscription`,
        text: 'Receive updates and manage privacy settings. Proceed with subscription?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00669e',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          container: 'custom-swal-container' // Add a custom class to the container
        }
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/email-subscribe`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
              },
              body: JSON.stringify({
                status: 1,

              })
            })
            if (response.status == 403 || response.status == 401) {

              let refreshToken = ''
              if (typeof localStorage !== 'undefined') {
                refreshToken = window.localStorage.getItem('refreshToken')
              }
      
              try {
                const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    refreshToken: refreshToken
                  })
                })
                if (tokenResponse.status == 200) {
                  const tokendata = await tokenResponse.json()
                  window.localStorage.setItem('token', tokendata.accessToken)
                  token = tokendata.accessToken
                  handleEmailsubscription();
                } else {
                  handleLogout();
                }
              } catch (error) {
                console.error(error)
              }
            } else if (response.status == 200) {
              Swal.fire({
                title: 'Confirmed!',
                icon: 'success',
                confirmButtonColor: '#00669e',
                customClass: {
                  container: 'custom-swal-container' // Add a custom class to the container
                }
              })

              fetchEdit_Data()
            }
          } catch (error) {
            console.error(error)
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: 'Cancelled',
            icon: 'error',
            confirmButtonColor: '#00669e',
            customClass: {
              container: 'custom-swal-container' // Add a custom class to the container
            }
          })
        }
      })
    } else {
      Swal.fire({
        title: `Confirm Unsubscribe`,
        text: 'You will stop receiving updates. Are you sure you want to unsubscribe?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00669e',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          container: 'custom-swal-container' // Add a custom class to the container
        }
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/email-subscribe`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
              },
              body: JSON.stringify({
                status: 0
              })
            })

            if (response.status == 403 || response.status == 401) {

              let refreshToken = ''
              if (typeof localStorage !== 'undefined') {
                refreshToken = window.localStorage.getItem('refreshToken')
              }
      
              try {
                const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    refreshToken: refreshToken
                  })
                })
                if (tokenResponse.status == 200) {
                  const tokendata = await tokenResponse.json()
                  window.localStorage.setItem('token', tokendata.accessToken)
                  token = tokendata.accessToken
                  handleEmailsubscription();
                } else {
                  handleLogout();
                }
              } catch (error) {
                console.error(error)
              }
            } else if (response.status == 200) {
              Swal.fire({
                title: 'Confirmed!',
                icon: 'success',
                confirmButtonColor: '#00669e',
                customClass: {
                  container: 'custom-swal-container' // Add a custom class to the container
                }
              })

              fetchEdit_Data()
            }
          } catch (error) {
            console.error(error)
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: 'Cancelled',
            icon: 'error',
            confirmButtonColor: '#00669e',
            customClass: {
              container: 'custom-swal-container' // Add a custom class to the container
            }
          })
        }
      })
    }

  }

  return (
    <div className="home-page">
      <Helmet>
        <title>Profile Setting - {themeConfig.templateName}</title>
        <meta name="description" content="This is the Manage Profile page of your site." />
      </Helmet>
      <NavbarLog />
      <Container sx={{ py: 3, my: 4, minHeight:'100vh' }}>
        <Card sx={{ my: 3,boxShadow:{xs:0, md:"rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;"} }}>
          <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={0.2} sx={{ flexGrow: 1, justifyContent: 'space-around', mt: 0.2, gap: 1 }}>
              <Hidden smDown>
                <Common />
              </Hidden>

              <Grid item xs={12} md={8.8}>
                <div>

                  {commpleteProStatus == 0 && (
                    <Alert
                      icon={false}
                      sx={{
                        mb: 3,
                        backgroundColor: '#fff6e5', // Light yellow background
                        color: '#e4a52b', // Highlight color for the text
                      }}
                    >
                      <AlertTitle
                        sx={{
                          fontWeight: 500,
                          mb: (theme) => `${theme.spacing(1)} !important`,
                          color: '#e4a52b', // Same color for consistency
                        }}
                      >
                        Complete Your Profile
                      </AlertTitle>
                      Please consider filling out your gender, NIC, and birthday for a better experience.
                    </Alert>
                  )}

                  <Card sx={{ mb: 2, width: '100%' }}>
                    <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Profile Setting</Typography>} sx={{ background: '#00669e', color: 'white', mb: 1 }} />
                    <CardContent>
                      <form onSubmit={handleSubmit} style={{ width: '100%', color: 'white', borderRadius: '5px' }}>
                        <Grid container direction="column" spacing={2} alignItems="center">
                          <Grid item xs={12}>
                            <Box position="relative" display="inline-block">
                              <Avatar
                                src={edit_Data.profile}
                                alt="User Picture"
                                sx={{
                                  width: { xs: 100, md: 150 },
                                  height: { xs: 100, md: 150 },
                                  border: '1px solid black',
                                  borderRadius: "50%",
                                  mt: 3
                                }}
                              />
                              <label htmlFor="file-upload-input" style={{ cursor: 'pointer' }}>
                                <PhotoCameraIcon
                                  sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    backgroundColor: 'white',
                                    borderRadius: '50%',
                                    padding: '2px',
                                    color: 'black',
                                    fontSize: { xs: '2rem', md: '2.5rem' },
                                  }}
                                />
                              </label>
                              <input
                                id="file-upload-input"
                                type="file"
                                accept=".jpg, .png"
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                  handleFileChange(e);
                                  setEdit_Data({ ...edit_Data, profile: URL.createObjectURL(e.target.files[0]) });
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sx={{ width: '100%' }}>
                            <Grid container spacing={2} sx={{ mt: 3 }} >
                              {/* First Name */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' }, flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100}}>
                                    First Name:
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white', borderRadius: '10px !important' }}
                                    value={edit_Data.stu_fname}
                                    onChange={(event) => {
                                      setEdit_Data({ ...edit_Data, stu_fname: event.target.value });
                                      setFirstNameError(''); // Clear error when typing
                                    }}
                                    error={!!firstNameError}  // Show error if firstNameError is set
                                    helperText={firstNameError}  // Display error message below the field
                                  />
                                </Box>
                              </Grid>

                              {/* Last Name */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100,}}>
                                    Last Name:
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white', borderRadius: '10px !important' }}
                                    value={edit_Data.stu_lname}
                                    onChange={(event) => {
                                      setEdit_Data({ ...edit_Data, stu_lname: event.target.value })
                                      setLastNameError('')
                                    }}
                                    error={!!lastNameError}  // Show error if lastNameError is set
                                    helperText={lastNameError}  // Display error message below the field
                                  />
                                </Box>
                              </Grid>

                              {/* NIC */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100, }}>
                                    NIC:
                                  </Typography>
                                  <TextField
                                    error={!!nicError}
                                    helperText={nicError}
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white', borderRadius: '10px !important' }}
                                    value={edit_Data.NIC}
                                    onChange={(event) => setEdit_Data({ ...edit_Data, NIC: event.target.value })}
                                  />
                                </Box>
                              </Grid>

                              {/* Mobile Number */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100, }}>
                                    Mobile:
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white', borderRadius: '10px !important' }}
                                    value={edit_Data.phone_num}
                                    InputProps={{ readOnly: true }}
                                  />
                                </Box>
                              </Grid>

                              {/* Email */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100, }}>
                                    Email:
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white', borderRadius: '10px !important' }}
                                    value={edit_Data.email}
                                    InputProps={{ readOnly: true }}
                                  />
                                </Box>
                              </Grid>

                              {/* Address */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100, }}>
                                    Address:
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white', borderRadius: '10px !important' }}
                                    value={edit_Data.address}
                                    onChange={(event) => {
                                      setEdit_Data({ ...edit_Data, address: event.target.value })
                                      setAddressError('')
                                    }}
                                    error={!!addressError}  // Show error if addressError is set
                                    helperText={addressError}  // Display error message below the field
                                  />
                                </Box>
                              </Grid>

                              {/* City */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100, }}>
                                    City:
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white', borderRadius: '10px !important' }}
                                    value={edit_Data.city}
                                    onChange={(event) => {
                                      setEdit_Data({ ...edit_Data, city: event.target.value })
                                      setCityError('')
                                    }}
                                    error={!!cityError}  // Show error if cityError is set
                                    helperText={cityError}  // Display error message below the field
                                  />
                                </Box>
                              </Grid>

                              {/* Country */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100, }}>
                                    Country:
                                  </Typography>
                                  <Autocomplete
                                    disablePortal
                                    options={countries}
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white' }}
                                    value={edit_Data.country || null}
                                    onChange={(event, newValue) => {
                                      setEdit_Data({ ...edit_Data, country: newValue })
                                      setCountryError('')
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        error={!!countryError}  // Show error if countryError is set
                                        helperText={countryError}  // Display error message below the field
                                      />
                                    )}
                                  />
                                </Box>
                              </Grid>

                              {/* Gender */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100, }}>
                                    Gender:
                                  </Typography>
                                  <Select
                                    fullWidth
                                    size="small"
                                    sx={{ backgroundColor: 'white' }}
                                    value={edit_Data.gender}
                                    onChange={(event) => setEdit_Data({ ...edit_Data, gender: event.target.value })}
                                  >
                                    <MenuItem value="male">Male</MenuItem>
                                    <MenuItem value="female">Female</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                  </Select>
                                </Box>
                              </Grid>

                              {/* Birthday */}
                              <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'center' },  flexDirection: { xs: 'column', sm: 'row' } }}>
                                  <Typography sx={{ color: 'black', marginRight: 1, minWidth: 100, }}>
                                    Birthday:
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    type="date"
                                    value={dayjs(edit_Data.birthday).format('YYYY-MM-DD')}
                                    onChange={(event) => setEdit_Data({ ...edit_Data, birthday: event.target.value })}
                                    size="small"
                                    sx={{
                                      backgroundColor: 'white',
                                      '& input': {
                                        color: edit_Data.birthday ? 'black' : '#9e9e9e', 
                                      },
                                    }}
                                    inputProps={{
                                      max: dayjs().format('YYYY-MM-DD'), 
                                    }}
                                  />
                                </Box>
                              </Grid>
                            </Grid>

                          </Grid>

                          <Grid item xs={12} sx={{ width: '100%', textAlign: { xs: 'center', sm: 'right' } }}>
                            <Button
                              type="submit"
                              variant="contained"
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#0d2353",
                                borderRadius: '8px',
                                mt: 4,
                                mr: { sm: 1 }
                              }}
                            >
                              Save Changes
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Card>

                  <Card sx={{ mb: 1,  }}>
                    <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Notification</Typography>} sx={{ background: '#00669e', color: 'white', mb: 1 }} />
                    <CardContent sx={{display:'flex', flexDirection:'flex-start'}}>
                      
                        <FormGroup>
                          <FormControlLabel
                            control={<Switch checked={edit_Data.email_subscribe === 1 ? true : false} onChange={handleEmailsubscription} name="email_sub" />}
                            label={<Typography sx={{ color: '' }}>Email Subscription</Typography>}
                            labelPlacement='start'
                          />
                        </FormGroup>
                      
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </div>
  );
}


export default ProfileSetting;


