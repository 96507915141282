// ** React Imports
import React, { useState, useEffect } from 'react'; // Added 'React,'

// ** MUI Imports
import Box from '@mui/material/Box';
import {
    Container,
} from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import AlertTitle from '@mui/material/AlertTitle';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import themeConfig from '../config/themeConfig';
import useLogout from '../hooks/logouthook';
import { CardHeader } from '@mui/material';
import { Link } from 'react-router-dom';

// ** Icon Imports
import { FormHelperText, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import NavbarLog from '../components/navbarlogged';
import Footer from '../components/footer';
import TempSidebar from '../components/TempSidebar';
import SetPassword from '../components/SetPassword';



const schema = yup.object().shape({
    password: yup.string().min(6).required('Password is required'),
    currentPassword: yup.string().required('Current Password is required'),
    confirmNewPassword: yup
        .string()
        .required('Confirm New Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const ChangePassword = () => {
    const [values, setValues] = useState({
        showCurrentPassword: false,
        showNewPassword: false,
        showConfirmNewPassword: false,
    });
    const [isAvailblePassowrd, setIsAvailblePassowrd] = useState(true)

    const { handleLogout } = useLogout();
    const theme = useTheme()
    const navigate = useNavigate('');

    useEffect(() => {
        window.scrollTo(0, 0);
        checkPassowrd()
    }, []);


    let token = ''
    if (typeof localStorage !== 'undefined') {
        token = window.localStorage.getItem('token')
    }

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const handleClickShowCurrentPassword = () => {
        setValues({ ...values, showCurrentPassword: !values.showCurrentPassword });
    };

    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
    };

    const handleClickShowConfirmNewPassword = () => {
        setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword });
    };

    const onSubmit = async (data) => {
        Swal.fire({
            title: `Change Password`,
            text: `Are you sure you want to change the password?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#00669e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm',
            reverseButtons: true,
            customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
            }
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/change-password`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            password: data.password,
                            currentPassword: data.currentPassword,
                        })
                    })

                    if (response.status == 403 || response.status == 401) {

                        let refreshToken = ''
                        if (typeof localStorage !== 'undefined') {
                            refreshToken = window.localStorage.getItem('refreshToken')
                        }

                        try {
                            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    refreshToken: refreshToken
                                })
                            })
                            if (tokenResponse.status == 200) {
                                const tokendata = await tokenResponse.json()
                                window.localStorage.setItem('token', tokendata.accessToken)
                                token = tokendata.accessToken
                                onSubmit(data)
                            } else {
                                handleLogout();
                            }
                        } catch (error) {
                            console.error(error)
                        }
                    } else if (response.status == 200) {
                        const user = await response.json()
                        Swal.fire({
                            title: 'Confirmed!', icon: 'success', confirmButtonColor: '#00669e', customClass: {
                                container: 'custom-swal-container', // Add a custom class to the container
                            },
                        });
                        setValue('password', '')
                        setValue('currentPassword', '')
                        setValue('confirmNewPassword', '')
                    } else {
                        const user = await response.json()
                        Swal.fire({
                            title: 'Cancelled',
                            text: user.message,
                            icon: 'error',
                            confirmButtonColor: '#00669e',
                            customClass: {
                                container: 'custom-swal-container', // Add a custom class to the container
                            },
                        })
                    }
                } catch (error) {
                    console.log(error)
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    icon: 'error',
                    confirmButtonColor: '#00669e',
                    customClass: {
                        container: 'custom-swal-container' // Add a custom class to the container
                    }
                })
            }
        })


    }

    const checkPassowrd = async () => {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/password-check`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({

            }),
        });
        if (response.status === 403 || response.status === 401) {
            let refreshToken = '';
            if (typeof localStorage !== 'undefined') {
                refreshToken = window.localStorage.getItem('refreshToken');
            }

            try {
                const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        refreshToken: refreshToken,
                    }),
                });

                if (tokenResponse.status === 200) {
                    const tokendata = await tokenResponse.json();
                    window.localStorage.setItem('token', tokendata.accessToken);
                    token = tokendata.accessToken;
                    return checkPassowrd();
                } else {
                    handleLogout();
                }
            } catch (error) {
                console.error('Error refreshing token:', error);
                handleLogout();
            }
            return;
        }

        const data = await response.json()
        if (response.status === 200) {

            setIsAvailblePassowrd(data.password)
        } else {
            setIsAvailblePassowrd(true)
        }

    }

    return (
        <div>
            <Helmet>
                <title>Change Password - {themeConfig.templateName}</title>
                <meta name="description" content="This is the Change Password page of your site." />
            </Helmet>
            <NavbarLog />
            <Container sx={{ py: 3, my: 4, minHeight:'100vh' }}>
                <Card sx={{ my: 3, boxShadow: { xs: 0, md: "rgba(76, 78, 100, 0.22) 0px 2px 10px 0px;" } }}>
                    <CardContent sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Grid container spacing={2} sx={{ mt: 0.2, mb: 2, backgroundColor: '' }}>

                            <Grid item xs={12} md={3.2} sx={{}}>
                                <TempSidebar />
                            </Grid>

                            <Grid item xs={12} md={8.8} sx={{}}>

                                {isAvailblePassowrd === true ? (
                                    <Card sx={{ width: '100%', boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px' }}>
                                        <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Change Password</Typography>} sx={{ background: '#00669e', color: 'white', mb: 1 }} />
                                        <CardContent>
                                            <Alert
                                                icon={false}
                                                sx={{
                                                    mb: 3,
                                                    backgroundColor: '#fff6e5', // Light yellow background
                                                    color: '#e4a52b', // Black text for contrast
                                                }}
                                            >
                                                <AlertTitle
                                                    sx={{
                                                        fontWeight: 500,
                                                        mb: (theme) => `${theme.spacing(1)} !important`,
                                                        color: '#e4a52b' // Ensure the title text is also in black for visibility
                                                    }}
                                                >
                                                    Ensure that these requirements are met
                                                </AlertTitle>
                                                Minimum 6 characters long, uppercase & symbol
                                            </Alert>


                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12}>
                                                        <FormControl fullWidth size='small'>
                                                            <InputLabel htmlFor='user-view-security-current-password'>Current Password</InputLabel>
                                                            <OutlinedInput
                                                                label='Current Password'
                                                                id='user-view-security-current-password'
                                                                {...register('currentPassword')}
                                                                error={Boolean(errors.currentPassword)}
                                                                type={values.showCurrentPassword ? 'text' : 'password'}
                                                                endAdornment={
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            edge='end'
                                                                            onClick={handleClickShowCurrentPassword}
                                                                            onMouseDown={(e) => e.preventDefault()}
                                                                            aria-label='toggle password visibility'
                                                                        >
                                                                            {values.showCurrentPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <FormHelperText error id="user-view-security-current-password">
                                                                {errors.currentPassword && errors.currentPassword.message}
                                                            </FormHelperText>

                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <FormControl fullWidth size='small'>
                                                            <InputLabel htmlFor='user-view-security-new-password'>New Password</InputLabel>
                                                            <OutlinedInput
                                                                label='New Password'
                                                                id='user-view-security-new-password'
                                                                {...register('password')}
                                                                error={Boolean(errors.password)}

                                                                type={values.showNewPassword ? 'text' : 'password'}
                                                                endAdornment={
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            edge='end'
                                                                            onClick={handleClickShowNewPassword}
                                                                            onMouseDown={(e) => e.preventDefault()}
                                                                            aria-label='toggle password visibility'
                                                                        >
                                                                            {values.showNewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <FormHelperText error id="user-view-security-current-password">
                                                                {errors.password && errors.password.message}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <FormControl fullWidth size='small'>
                                                            <InputLabel htmlFor='user-view-security-confirm-new-password'>Confirm New Password</InputLabel>
                                                            <OutlinedInput
                                                                label='Confirm New Password'
                                                                id='user-view-security-confirm-new-password'
                                                                {...register('confirmNewPassword')}
                                                                error={Boolean(errors.confirmNewPassword)}
                                                                type={values.showConfirmNewPassword ? 'text' : 'password'}
                                                                endAdornment={
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            edge='end'
                                                                            onMouseDown={(e) => e.preventDefault()}
                                                                            aria-label='toggle password visibility'
                                                                            onClick={handleClickShowConfirmNewPassword}
                                                                        >
                                                                            {values.showConfirmNewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <FormHelperText error id="user-view-security-current-password">
                                                                {errors.confirmNewPassword && errors.confirmNewPassword.message}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap:1, flexDirection:{xs:'column-reverse', sm:'row'} }}>
                                                            <Box>
                                                                <Link to={'/forgot_password'} target='_blank' style={{ color: '#00669e', textDecoration: 'none' }}> Forgot your current password? Reset it here.</Link>

                                                            </Box>
                                                            <Box sx={{ textAlign: 'rigt' }}>
                                                                <Button type='submit' variant='contained' sx={{ backgroundColor: '#00669e', ":hover": { backgroundColor: '#00669e' } }}>
                                                                    Change Password
                                                                </Button>
                                                            </Box>
                                                        </Box>

                                                    </Grid>
                                                    

                                                </Grid>
                                            </form>
                                        </CardContent>
                                    </Card>
                                ) : (
                                    <SetPassword checkPassowrd={checkPassowrd} />
                                )}

                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>


            </Container>

            <Footer />
        </div>
    )
}

export default ChangePassword