import * as React from 'react';
import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleFilledOutlinedIcon from '@mui/icons-material/PauseCircleFilledOutlined';
import HailIcon from '@mui/icons-material/Hail';
import ForumIcon from '@mui/icons-material/Forum';
import Collapse from '@mui/material/Collapse';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useLocation  } from 'react-router-dom';
import TodayIcon from '@mui/icons-material/Today';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import MessageIcon from '@mui/icons-material/Message';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import TaskIcon from '@mui/icons-material/Task';
import PersonIcon from '@mui/icons-material/Person';
import { Icon } from "@iconify/react";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import LockResetIcon from '@mui/icons-material/LockReset';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import { Chip } from '@mui/material';
import { useMessageCount } from '../context/MessageContext';
import Swal from 'sweetalert2';


  

function LogOut() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {

    Swal.fire({
      title: `Logout Confirmation`,
      text: `Are you sure you want to log out?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00669e',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
      reverseButtons: true,
      customClass: {
        container: 'custom-swal-container' // Add a custom class to the container
      }
    }).then(async result => {
      if (result.isConfirmed) {
        localStorage.removeItem('studentID');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');


        if (location.pathname === '/') {
          window.location.reload();
        } else {
          navigate('/');
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          icon: 'error',
          confirmButtonColor: '#00669e',
          customClass: {
            container: 'custom-swal-container' // Add a custom class to the container
          }
        })
      }
    })

  };


  return (
    <ListItemButton onClick={handleLogout}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>
  );
};



export const MainListItems = () => {
  const location = useLocation();

  return (
    <React.Fragment>
      <ListItemButton
        component={Link}
        to="/dashboard"
        sx={{ 
          backgroundColor: location.pathname === '/dashboard' ? '#cce0ecb5' : '', 
          borderRadius: 2, 
          color: location.pathname === '/dashboard' ? '#00669e' : '', 
          '&:hover': {
            backgroundColor: location.pathname === '/dashboard' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)', // Keeps the background color on hover
          }
        }}
      >
        <ListItemIcon sx={{ color: location.pathname === '/dashboard' ? '#00669e' : '' }}>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText 
          primary="Dashboard" 
          sx={{ color: location.pathname === '/dashboard' ? '#00669e' : '' }}
        />
      </ListItemButton>
    </React.Fragment>


  );
};

// #00669e

export const SecondaryListItems = () => {
  const [open, setOpen] = React.useState(true);
  const location = useLocation();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
  <React.Fragment>
<ListItemButton 
    component={Link} 
    to="/today_class" 
    sx={{ backgroundColor: location.pathname === '/today_class' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/today_class' ? '#00669e' : '', 
      '&:hover': {
        backgroundColor: location.pathname === '/today_class' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
      }  }}>
      <ListItemIcon sx={{ color: location.pathname === '/today_class' ? '#00669e' : '' }}>
       <TodayIcon/>
      </ListItemIcon>
      <ListItemText primary="Today Classes" sx={{ color: location.pathname === '/today_class' ? '#00669e' : '' }} />
  </ListItemButton>

  <ListItemButton  
    component={Link} 
    to="/upcoming_class"
    sx={{ backgroundColor: location.pathname === '/upcoming_class' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/upcoming_class' ? '#00669e' : '', 
      '&:hover': {
        backgroundColor: location.pathname === '/upcoming_class' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
      } }}>
      <ListItemIcon sx={{ color: location.pathname === '/upcoming_class' ? '#00669e' : '' }}>
        <CalendarMonthIcon />
      </ListItemIcon>
      <ListItemText primary="Upcoming Classes" sx={{ color: location.pathname === '/upcoming_class' ? '#00669e' : '' }} />
    </ListItemButton>

      <ListItemButton  
        component={Link} 
        to="/pendingApproval_class"
        sx={{ backgroundColor: location.pathname === '/pendingApproval_class' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/pendingApproval_class' ? '#00669e' : '', 
          '&:hover': {
            backgroundColor: location.pathname === '/pendingApproval_class' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
          } }}>
        <ListItemIcon sx={{ color: location.pathname === '/pendingApproval_class' ? '#00669e' : '' }}>
          <PendingActionsIcon />
        </ListItemIcon>
        <ListItemText primary="Pending Approvals" sx={{ color: location.pathname === '/pendingApproval_class' ? '#00669e' : '' }} />
      </ListItemButton>

    <ListItemButton 
      component={Link} 
      to="/completed_classes"
      sx={{ backgroundColor: location.pathname === '/completed_classes' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/completed_classes' ? '#00669e' : '', 
        '&:hover': {
          backgroundColor: location.pathname === '/completed_classes' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
        } }}>
      <ListItemIcon sx={{ color: location.pathname === '/completed_classes' ? '#00669e' : '' }}>
        <TaskIcon />
      </ListItemIcon>
      <ListItemText primary="Completed Classes" sx={{ color: location.pathname === '/completed_classes' ? '#00669e' : '' }} />
    </ListItemButton>
  </React.Fragment>
 );
};


export const ThirdListItems = () => {
  const location = useLocation();
  const {messagecount, setMessagecount}= useMessageCount() ;
  const studentID = localStorage.getItem('studentID');
 


  useEffect(() => {
    fetchMessageCount()
  }, []);

  const fetchMessageCount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/messagecount`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'

        },
        body: JSON.stringify({
          id:studentID
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setMessagecount(getData.result.count);
    } catch (err) {
      console.error('Error fetching available dates:', err);
    }
  };
  
  return (
  <React.Fragment>
    <ListItemButton 
      component={Link} 
      to="/myteachers"
      sx={{ backgroundColor: location.pathname === '/myteachers' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/myteachers' ? '#00669e' : '', 
        '&:hover': {
          backgroundColor: location.pathname === '/myteachers' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
        } }}>
      <ListItemIcon sx={{ color: location.pathname === '/myteachers' ? '#00669e' : '' }}>
        <PersonIcon/>
      </ListItemIcon>
      <ListItemText primary="My Teachers" sx={{ color: location.pathname === '/myteachers' ? '#00669e' : '' }} />
    </ListItemButton>

    <ListItemButton 
      component={Link} 
      to="/messages"
      sx={{ backgroundColor: location.pathname === '/messages' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/messages' ? '#00669e' : '', 
        '&:hover': {
          backgroundColor: location.pathname === '/messages' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
        } }}>
      <ListItemIcon sx={{ color: location.pathname === '/messages' ? '#00669e' : '' }}>
        <MessageIcon />
      </ListItemIcon>
      <ListItemText primary="Messages" sx={{ color: location.pathname === '/messages' ? '#00669e' : '' }} />
      {messagecount > 0 &&(
      <Chip label={messagecount} color="error" sx={{'& .MuiChip-label': { px: 1.25, lineHeight: 1.385, textTransform: 'capitalize' }}}/>
       )}
    </ListItemButton>

    <ListItemButton 
      component={Link} 
      to="/account_topup"
      sx={{ backgroundColor: location.pathname === '/account_topup' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/account_topup' ? '#00669e' : '', 
        '&:hover': {
          backgroundColor: location.pathname === '/account_topup' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
        } }}>
      <ListItemIcon sx={{ color: location.pathname === '/account_topup' ? '#00669e' : '' }}>
      <Icon icon="fa-brands:cc-visa" width="1.3rem" height="1.3rem" />
      </ListItemIcon>
      <ListItemText primary="Account Top-Up" sx={{ color: location.pathname === '/account_topup' ? '#00669e' : '' }} />
    </ListItemButton>

    <ListItemButton 
      component={Link} 
      to="/slip_upload"
      sx={{ backgroundColor: location.pathname === '/slip_upload' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/slip_upload' ? '#00669e' : '', 
        '&:hover': {
          backgroundColor: location.pathname === '/slip_upload' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
        } }}>
      <ListItemIcon sx={{ color: location.pathname === '/slip_upload' ? '#00669e' : '' }}>
        <AccountBalanceIcon />
      </ListItemIcon>
      <ListItemText primary="Bank Payments" sx={{ color: location.pathname === '/slip_upload' ? '#00669e' : '' }} />
    </ListItemButton>

    <ListItemButton 
      component={Link} 
      to="/topuphistory"
      sx={{ backgroundColor: location.pathname === '/topuphistory' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/topuphistory' ? '#00669e' : '', 
        '&:hover': {
          backgroundColor: location.pathname === '/topuphistory' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
        } }}>
      <ListItemIcon sx={{ color: location.pathname === '/topuphistory' ? '#00669e' : '' }}>
      <Icon icon="subway:file-1" width="1.3rem" height="1.3rem" />
      </ListItemIcon>
      <ListItemText primary="Top-Up History" sx={{ color: location.pathname === '/topuphistory' ? '#00669e' : '' }} />
    </ListItemButton>

    <ListItemButton 
      component={Link} 
      to="/billing_history"
      sx={{ backgroundColor: location.pathname === '/billing_history' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/billing_history' ? '#00669e' : '', 
        '&:hover': {
          backgroundColor: location.pathname === '/billing_history' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
        } }}>
      <ListItemIcon sx={{ color: location.pathname === '/billing_history' ? '#00669e' : '' }}>
      <Icon icon="material-symbols:content-paste" width="1.3rem" height="1.3rem" />
      </ListItemIcon>
      <ListItemText primary="Billing History" sx={{ color: location.pathname === '/billing_history' ? '#00669e' : '' }} />
    </ListItemButton>

    {/* <ListItemButton 
       component={Link} 
       to="/My_Post"
       sx={{ backgroundColor: location.pathname === '/My_Post' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/My_Post' ? '#00669e' : '', 
      '&:hover': {
        backgroundColor: location.pathname === '/My_Post' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
      } }}>
      <ListItemIcon sx={{ color: location.pathname === '/My_Post' ? '#00669e' : '' }}>
        <ForumIcon />
      </ListItemIcon>
      <ListItemText primary="My Post" sx={{ color: location.pathname === '/My_Post' ? '#00669e' : '' }} />
    </ListItemButton> */}

    <ListItemButton 
       component={Link} 
       to="/ticket"
       sx={{ backgroundColor: location.pathname === '/ticket' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/ticket' ? '#00669e' : '', 
      '&:hover': {
        backgroundColor: location.pathname === '/ticket' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
      } }}>
      <ListItemIcon sx={{ color: location.pathname === '/ticket' ? '#00669e' : '' }}>
        <BookOnlineIcon />
      </ListItemIcon>
      <ListItemText primary=" Support Tickets" sx={{ color: location.pathname === '/ticket' ? '#00669e' : '' }} />
    </ListItemButton>

    <ListItemButton 
      component={Link} 
      to="/profile_setting"
      sx={{ backgroundColor: location.pathname === '/profile_setting' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/profile_setting' ? '#00669e' : '', 
        '&:hover': {
          backgroundColor: location.pathname === '/profile_setting' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
        } }}>
        <ListItemIcon sx={{ color: location.pathname === '/profile_setting' ? '#00669e' : '' }}>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Manage Profile" sx={{ color: location.pathname === '/profile_setting' ? '#00669e' : '' }} />
    </ListItemButton>

    <ListItemButton
      component={Link}
      to="/change_password"
      sx={{ backgroundColor: location.pathname === '/change_password' ? '#cce0ecb5' : '', borderRadius:2, color: location.pathname === '/change_password' ? '#00669e' : '',
      '&:hover': {
      backgroundColor: location.pathname === '/change_password' ? '#cce0ecb5' : 'rgba(0, 0, 0, 0.08)',
      } }}>
      <ListItemIcon sx={{ color: location.pathname === '/change_password' ? '#00669e' : '' }}>
      <LockResetIcon />
      </ListItemIcon>
      <ListItemText primary="Change Password" sx={{ color: location.pathname === '/change_password' ? '#00669e' : '' }} />
      </ListItemButton>

    <LogOut/>
  </React.Fragment>
  );
};
