import React from 'react';
import TeacherSign from './pages/teacher_signup';
import HomePage from './pages/home';
import TeacherProfile from './pages/teacher_profile';
import PrivacyPolicy from './pages/privacy_policy';
import TermsConditions from './pages/T_C';
import Dashboard from './pages/dashboard';
import MyClasses from './pages/myclasses';
import UnpaidClasses from './pages/unpaidclasses';
import CompletedClasses from './pages/completed_classes';
import { BrowserRouter,Routes, Route} from 'react-router-dom';
import MyTeachers from './pages/myteachers';
import TodayClass from './pages/today_class';
import UpcomingClass from './pages/upcoming_class';
import PendingApprovalClass from './pages/pendingApproval_class';
import Messages from './pages/messages';
import AccountTopUp from './pages/account_topup';
import SlipUpload from './pages/slip_upload';
import TopUpHistory from './pages/topuphistory';
import BillingHistory from './pages/billing_history';
import ProfileSetting from './pages/profile_setting';
import BookNow from './pages/booknow';
import Forum from './pages/forum';
import ForumLogged from './pages/forum_logged';
import SessionChecker from './components/sessionchecker'
import YourPostPage from './pages/your_post';
import ChangePassword from './pages/change_password';
import OneForumView from './components/common_forum/onePostView';
import LogOnePostView from './components/forum_details/LogOnepostView';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import TicketPage from './pages/ticket';
import TicketViewPage from './pages/ticket/view';
import ForgotPassword from './pages/forgot_password';
import ResetPassword from './pages/reset_password';
import EmailVerify from './pages/email_verify';
import TeacherEmailVerify from './pages/teacher_emailverification';
import StudentEmailVerify from './pages/student_login_verification';
import TeacherAgreement from './pages/teacher_agreement';
import EmailUnsubscribe from './pages/email-unsubscribe';
import RateClass from './pages/rate-class';
import { MessageProvider } from './context/MessageContext';






function App() {
  return (
    <MessageProvider>
    <ThemeProvider theme={theme}>
    <div className='container'>
      <BrowserRouter>
      <SessionChecker>
       <Routes>
          
          <Route index element={<HomePage/>}/>
          <Route path='/teacher_signup' element={<TeacherSign />}/>
          <Route path='/*' element={<TeacherProfile/>}/>
          <Route path='/privacy_policy' element={<PrivacyPolicy/>}/>
          <Route path='/T_C' element={<TermsConditions/>}/>
          <Route path='/dashboard' element={<Dashboard/>}/>
          <Route path='/myclasses' element={<MyClasses/>}/>
          <Route path='/today_class' element={<TodayClass/>}/>
          <Route path='/upcoming_class' element={<UpcomingClass/>}/>
          <Route path='/pendingApproval_class' element={<PendingApprovalClass/>}/>
          <Route path='/unpaidclasses' element={<UnpaidClasses/>}/>
          <Route path='/completed_classes' element={<CompletedClasses/>}/>
          <Route path='/myteachers' element={<MyTeachers/>}/>
          <Route path='/messages' element={<Messages/>}/>
          <Route path='/messages/:t_id/:studentID' element={<Messages />} />
          <Route path='/account_topup' element={<AccountTopUp/>}/>
          <Route path='/slip_upload' element={<SlipUpload/>}/>
          <Route path='/topuphistory' element={<TopUpHistory/>}/>
          <Route path='/billing_history' element={<BillingHistory/>}/>
          <Route path='/profile_setting' element={<ProfileSetting/>}/>
          <Route path='/booknow' element={<BookNow/>}/>
          <Route path='/community' element={<Forum/>}/>
          <Route path='/community_knowledge' element={<ForumLogged/>}/>
          <Route path='/My_Post' element={<YourPostPage/>}/>
          <Route path='/change_password' element={<ChangePassword/>}/>
          <Route path='/community/:id/:title?' element={<OneForumView />} />
          <Route path='/community_knowledge/:id/:title?' element={<LogOnePostView />} />
          <Route path='/ticket' element={<TicketPage/>} />
          <Route path='/ticket/view/:id' element={<TicketViewPage/>} />
          <Route path='/forgot_password' element={<ForgotPassword/>}/>
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/email_verify' element={<EmailVerify />} />
          <Route path='/teacher_emailverification' element={<TeacherEmailVerify />} />
          <Route path='/student_emailverification' element={<StudentEmailVerify />} />
          <Route path='/teacher_agreement' element={<TeacherAgreement />} />
          <Route path="/email-unsubscribe" element={<EmailUnsubscribe />} />
          <Route path='/rate-class' element={<RateClass/>} />





        
          
       </Routes>
       </SessionChecker>
      </BrowserRouter>
     
    </div>
    </ThemeProvider>
    </MessageProvider>
  
  );
}

export default App;

