import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardActions, Typography, Button, Grid, Chip, Box, CircularProgress, TextField } from "@mui/material";
import { styled } from "@mui/system";
import Swal from 'sweetalert2'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import Star1 from '../components/completedcardstar';
import useLogout from '../hooks/logouthook';
import {Rating} from '@mui/material';


const RateClass = ({ }) => {
  const [classDetails, setClassDetails] = useState({
    class_end: 0,
    end_time: "",
    schedule_date: "",
    ssch_id: 0,
    start_time: "",
    stu_fname: "",
    stu_lname: "",
    stu_id: 0,
    t_id: 0,
    totalAmount: 0,
    tsch_id: '',
    rate: 0
  })
  const [logo, setLogo] = useState('')
  const [loading, setLoading] = useState(false)
  const [approveLoading, setApproveLoading] = useState(false)
  const [disapproveOpen, setDisapproveOpen] = useState(false)
  const [keepTimeslot, setKeepTimeslot] = useState(false)
  const [disapproveLoading, setDisapproveLoading] = useState(false)
  const [feedbacks, setFeedbacks] = useState("");
  const [stars, setStars] = useState(0);
  const [errors, setErrors] = useState('');
  const { handleLogout } = useLogout();


  const location = useLocation();

  // Extract the email parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const id = queryParams.get('id');


  console.log(id, token)

  useEffect(() => {
    fetchClass()
    fetchSystemSetting()
  }, [id])

  const fetchClass = async () => {
    setLoading(true)

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/rating_class`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

      },
      body: JSON.stringify({
        id: id,
        token: token
      })
    })
    const data = await response.json()
    if (response.status == 200) {
      setLoading(false)
      setClassDetails(data.result)
    } else {
      Swal.fire({
        title: `${data.message}`,
        icon: 'error',
        confirmButtonColor: '#00669e',
        customClass: {
          container: 'custom-swal-container'
        }
      })
    }
  }


  const handleApprove = () => {
    setApproveLoading(true)
    Swal.fire({
      title: `Approve class ${classDetails.ssch_id}`,
      text: `Are you sure you want to approve this class?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00669e',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        container: 'custom-swal-container' // Add a custom class to the container
      }
    }).then(async result => {
      if (result.isConfirmed) {

        try {
          const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/classes/direct-approve`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              t_id: classDetails.t_id,
              id: classDetails.ssch_id,
              stu_id: classDetails.stu_id
            })
          })
          const data = await response.json()

          if (response.status == 200) {
            setApproveLoading(false)
            Swal.fire({
              title: `${data.message}`,
              icon: 'success',
              confirmButtonColor: '#00669e',
              customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
              }
            })
            fetchClass()

          } else {
            Swal.fire({
              title: `${data.message}`,
              icon: 'error',
              confirmButtonColor: '#00669e',
              customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
              }
            })

          }
        } catch (error) {
          console.error('Error:', error)
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          icon: 'error',
          confirmButtonColor: '#00669e',
          customClass: {
            container: 'custom-swal-container' // Add a custom class to the container
          }
        })
      }
    })
  }

  const fetchSystemSetting = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      if (response.status == 200) {
        const data = await response.json()
        setLogo(data.result.logo)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getCategory = name => {
    return name === 0
      ? 'Pending Approval'
      : name === 1
        ? 'Upcoming'
        : name === 2
          ? 'Ongoing'
          : name === 3
            ? 'Completed'
            : name === 4
              ? 'Cancel'
              : null
  }

  const getStatusColor = status => {
    return status == '0'
      ? 'orange'
      : status == '1'
        ? '#17A2B8'
        : status == '2'
          ? '#00669e'
          : status == '3'
            ? '#20b020'
            : status == '4'
              ? '#FF625F'
              : status == '5'
                ? '#be63f2'
                : null
  }

  const formatDate = dateString => {


    return new Date(dateString).toLocaleDateString('en-CA')
  }

  const handleConfirm = async () => {
    setDisapproveLoading(true)
    try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/classes/direct-disapprove-class`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: classDetails.ssch_id,
          stu_id: classDetails.stu_id,
          t_id: classDetails.t_id,
          schedule_date: classDetails.schedule_date,
          start_time: classDetails.start_time,
          end_time: classDetails.end_time,
          amount: classDetails.totalAmount,
          tsch_id: classDetails.tsch_id,
          keepTimeslot: keepTimeslot
        })
      })


      if (response.status == 200) {
        const data = await response.json()
        Swal.fire({
          title: `${data.message}`,
          icon: 'success',
          confirmButtonColor: '#00669e',
          customClass: {
            container: 'custom-swal-container' // Add a custom class to the container
          }
        })
        setDisapproveLoading(false)
        setDisapproveOpen(false)
        fetchClass()
      } else {
        const data = await response.json()
        Swal.fire({
          title: `${data.message}`,
          icon: 'error',
          confirmButtonColor: '#00669e',
          customClass: {
            container: 'custom-swal-container' // Add a custom class to the container
          }
        })
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleClose = () => {
    setDisapproveOpen(false)
    Swal.fire({
      title: 'Cancelled',
      icon: 'error',
      confirmButtonColor: '#00669e',
      customClass: {
        container: 'custom-swal-container' // Add a custom class to the container
      }
    })
  }

  const handleDisapprove = () => {

    setDisapproveOpen(true)
  }

  const handleTimeslot = () => {
    setKeepTimeslot(!keepTimeslot)
  }





  const handleSubmitRate = async (event, ssch_id) => {
    event.preventDefault();

    if (stars === 0) {
      setErrors("Please rate");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/DirectClassFeedback`, {
        method: 'POST',
        body: JSON.stringify({
          stars,
          feedback: feedbacks,
          ssch_id: id,
          studentID: classDetails.stu_id
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const data = await response.json();

      if (response.status === 200) {
        setStars(0);
        setFeedbacks("");
        fetchClass();
      } else {
        setErrors("rate failed");
      }

    } catch (error) {
      console.error("Error fetching data: ", error);
      setErrors({ [ssch_id]: "Error submitting rating and feedback" });
    }
  };

  return (
    <div>
      {/* <Head>
        <title>{`Approve Class(${classDetails?.ssch_id}) - ${themeConfig.templateName}`}</title>
      </Head> */}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', px: 2 }}>
        <Box>

          <Card variant="outlined" sx={{ boxShadow: 2, }}>
            <CardContent sx={{ textAlign: 'center', minWidth: 300 }}>
              <Box sx={{ textAlign: 'center', mb: 6, }}>
                <img
                  src={logo ? logo : ''}
                  alt='English teacher'
                  style={{ width: '200px', height: 'auto' }}
                />

              </Box>
              <Typography variant="h6" color="primary" gutterBottom>
                Class ID: {classDetails?.ssch_id}
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                <strong>Student:</strong> {`${classDetails?.stu_fname} ${classDetails?.stu_lname}`}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                <strong>Date:</strong> {formatDate(classDetails?.schedule_date)}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                <strong>Time:</strong> {`${classDetails?.start_time} - ${classDetails?.end_time}`}
              </Typography>
              {classDetails?.rate > 0 ? (

                <Box sx={{ mt: 1 }}>
                  <Rating
                    value={classDetails?.rate/20}
                    readOnly
                  />
                </Box>

              ) : (
                <>
                <Box sx={{ mt: 1 }}>
                  <Rating
                    value={stars}
                    onChange={(event, newValue) => {
                      setStars(newValue)
                    }}

                  />

                  <TextField
                    fullWidth
                    label="Feedback"
                    value={feedbacks}
                    onChange={(event) => setFeedbacks(event.target.value)}
                    sx={{ mt: 1 }}
                    error={!!errors}
                    helperText={errors}
                  />
                </Box>
                 <Button
                 type="submit"
                 variant="contained"
                 color="primary"
                 size="medium"
                 sx={{ mt: 2 }}
                 onClick={(event) => handleSubmitRate(event, classDetails?.ssch_id)}
               >
                 Submit
               </Button>
               </>
              )}

            </CardContent>

          </Card>
        </Box>
      </Box>

      <Dialog onClose={handleClose} open={disapproveOpen} fullWidth maxWidth='sm'>
        <DialogTitle
          id='customized-dialog-title'
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', paddingBottom: 0, pt: 2 }}
        >
          <IconButton aria-label='close' onClick={handleClose}>
            <Icon icon='mdi:close' />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ paddingTop: '0px', textAlign: 'center' }}>

            <Box sx={{ mb: 2, textAlign: 'center' }}>
              <Icon icon='mdi:alert-circle' color='error' fontSize={48} />
            </Box>

            <Box sx={{ mt: 1 }}>
              {/* Displaying class details */}
              <Typography variant="h6" sx={{ mb: 1 }}>
                Class ID: {classDetails.ssch_id}
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary', mb: 1 }}>
                Date: {classDetails.schedule_date ? formatDate(classDetails.schedule_date) : ''}
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                Time: {classDetails.start_time} - {classDetails.end_time}
              </Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
              <FormControlLabel
                control={<Checkbox checked={keepTimeslot} onChange={handleTimeslot} />}
                label="Keep Timeslot for Potential Rescheduling"
              />
            </Box>

            <Box sx={{ mt: 4, textAlign: 'center', fontSize: '18px' }}>
              Are you sure you want to disapprove this class?
            </Box>

            <Box sx={{ textAlign: 'center', mt: 4, display: 'flex', justifyContent: 'center', gap: 4 }}>
              <Button variant='contained' color='error' onClick={handleClose}>
                Cancel
              </Button>
              <Button variant='contained' color='primary' sx={{}} onClick={handleConfirm}
                startIcon={disapproveLoading ? (<CircularProgress size={20} />) : null}
                disabled={disapproveLoading}
              >
                {disapproveLoading ? 'Please wait' : 'Confirm Disapproval'}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}



export default RateClass